const DECIMAL_PLACES = 9;

const hasMoreThanDecimalPlaces = (
  totalValue: number,
  newTotalValue: number,
) => {
  if (!newTotalValue || !totalValue) {
    return false;
  }

  if (Math.abs(totalValue - newTotalValue) < 0.000001) {
    return false;
  }
  return true;
};

const removeDecimalPlaces = (num: number, decimalPlaces = DECIMAL_PLACES) => {
  if (!num) {
    return 0;
  }

  return Number(Number(num).toFixed(decimalPlaces));
};

export { hasMoreThanDecimalPlaces, removeDecimalPlaces };
