import { useDrawerForm } from '@refinedev/antd';
import { HttpError, useList } from '@refinedev/core';
import {
  FarmIcon,
  ItemsIcon,
  PurchaseIcon,
  SupplierIcon,
  UpdateIcon,
} from 'common/icons';
import {
  ListFiltersModel,
  RefineTableFiltersEnum,
  useRefineTableFilters,
} from 'common/refine-table-filters';
import { useTracking } from 'common/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RenderExpandIcon } from 'rc-table/lib/interface';
import { MouseEvent, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFarm } from 'src/organization';

import {
  OtherCurrencyModel,
  PurchaseDetailModel,
  PurchaseModel,
  PurchaseRequestModel,
} from './purchase.model';
import { usePurchaseDrawer } from './purchase-create/purchase-create.hook';
import * as Styled from './purchase-list.style';
import { PurchasePaymentRequestModel } from './purchase-payment/purchase-payment.model';
import { StatusEnum, StatusTranslationEnum } from './status.enum';

interface FilterFormModel {
  farms: string[];
  name: string;
  category: string;
}

const usePurchaseList = () => {
  const { t } = useTranslation();
  const { track } = useTracking();
  const { purchaseDrawer: receiveDrawer } = usePurchaseDrawer('edit');
  const { purchaseDrawer: invoiceDrawer } =
    usePurchaseDrawer<PurchasePaymentRequestModel>('edit');
  const { purchaseDrawer: createDrawer } =
    usePurchaseDrawer<PurchasePaymentRequestModel>('create', 'create');
  const { currentFarm } = useFarm();
  const { invoiceReceiptTableFms849 } = useFlags();

  const optionsStatus = useMemo(
    () => [
      {
        label: t(`status.${StatusTranslationEnum[StatusEnum.ToBill]}`),
        value: StatusEnum.ToBill,
      },
      {
        label: t(`status.${StatusTranslationEnum[StatusEnum.ToReceive]}`),
        value: StatusEnum.ToReceive,
      },
      {
        label: t(
          `status.${StatusTranslationEnum[StatusEnum.ToReceiveAndBill]}`,
        ),
        value: StatusEnum.ToReceiveAndBill,
      },
      {
        label: t(`status.${StatusTranslationEnum[StatusEnum.Completed]}`),
        value: StatusEnum.Completed,
      },
      {
        label: t(`status.${StatusTranslationEnum[StatusEnum.Cancelled]}`),
        value: StatusEnum.Cancelled,
      },
    ],
    [t],
  );

  const listFilters: ListFiltersModel[] = useMemo(
    () => [
      {
        label: t('purchase.farm'),
        field: 'farms',
        type: RefineTableFiltersEnum.MULTIPLE_SELECT,
        resource: 'farm',
        show: true,
        icon: <FarmIcon />,
      },
      {
        label: t('purchase.supplier'),
        field: 'suppliersId',
        type: RefineTableFiltersEnum.MULTIPLE_SELECT,
        resource: 'supplier',
        filters: [
          {
            field: 'disabled',
            operator: 'eq',
            value: false,
          },
        ],
        icon: <SupplierIcon />,
        show: false,
      },
      {
        label: t('purchase.items'),
        field: 'itemsCode',
        optionLabel: 'name',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'item',
        icon: <ItemsIcon />,
        optionComplementaryLabel: 'category',
        show: false,
      },
      {
        label: t('purchase.status'),
        field: 'status',
        type: RefineTableFiltersEnum.MULTIPLE_SELECT,
        show: false,
        icon: <UpdateIcon />,
        initialValue: [
          StatusEnum.ToBill,
          StatusEnum.ToReceive,
          StatusEnum.ToReceiveAndBill,
          StatusEnum.Completed,
        ],
        options: optionsStatus,
      },
      {
        label: t('purchase.table.purchase_number'),
        field: 'purchaseNumber',
        type: RefineTableFiltersEnum.INPUT,
        show: false,
        icon: <PurchaseIcon />,
      },
    ],
    [currentFarm?.name, optionsStatus, t],
  );

  const { tableProps, searchFormProps } = useRefineTableFilters<
    PurchaseModel,
    HttpError,
    FilterFormModel
  >({
    listFilters,
    resource: 'purchase-order',
  });

  const { data: otherCurrencyData } = useList<OtherCurrencyModel>({
    resource: 'other-currency',
    pagination: {
      current: 1,
      pageSize: 1000,
    },
  });

  useEffect(() => {
    if (currentFarm && searchFormProps) {
      searchFormProps.form?.setFieldsValue({
        farms: [currentFarm.name],
      });
      searchFormProps.form?.submit();
    }
  }, [currentFarm, searchFormProps.form?.setFieldValue]);

  const handleRenderExpandIcon: RenderExpandIcon<PurchaseModel> = useCallback(
    ({ expanded, onExpand, record }) => {
      if (!invoiceReceiptTableFms849) {
        return null;
      }
      return expanded ? (
        <Styled.Space onClick={(e) => onExpand(record, e)} align="center">
          <Styled.CustomChevronTopIcon />
        </Styled.Space>
      ) : (
        <Styled.Space onClick={(e) => onExpand(record, e)} align="center">
          <Styled.CustomChevronBottomIcon />
        </Styled.Space>
      );
    },
    [invoiceReceiptTableFms849],
  );

  const viewDrawer = useDrawerForm<
    PurchaseDetailModel,
    HttpError,
    PurchaseRequestModel
  >({
    action: 'edit',
    resource: 'purchase-order',
    redirect: false,
  });

  const otherCurrencyById = useMemo(() => {
    return otherCurrencyData?.data.reduce(
      (acc: Record<string, OtherCurrencyModel>, curr: OtherCurrencyModel) => {
        acc[curr.id] = curr;
        return acc;
      },
      {} as Record<string, OtherCurrencyModel>,
    );
  }, [otherCurrencyData]);

  const onRowClick = (event: MouseEvent<HTMLElement>, data: PurchaseModel) => {
    const target = event.target as HTMLElement;
    if (target.tagName !== 'TD') {
      event.stopPropagation();
      return;
    }

    viewDrawer.show(data.id);
  };

  const handleOnClickCreatePurchase = () => {
    track('Start Purchase');
    createDrawer.show();
  };

  const handleOnClickReceivePurchase = (id: string) => {
    track('Start Purchase Receipt');
    receiveDrawer.show(id);
  };

  const handleOnClickInvoicePurchase = (id: string) => {
    track('Start Purchase Invoice');
    invoiceDrawer.show(id);
  };

  return {
    tableProps,
    searchFormProps,
    listFilters,
    onRowClick,
    invoiceDrawer,
    receiveDrawer,
    createDrawer,
    viewDrawer,
    otherCurrencyById,
    handleRenderExpandIcon,
    handleOnClickCreatePurchase,
    handleOnClickReceivePurchase,
    handleOnClickInvoicePurchase,
  };
};

export { usePurchaseList };
