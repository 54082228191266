import { useTranslation } from 'react-i18next';

import * as Styled from './colored-value.style';

interface ColoredValueProps {
  value: number;
  stockUom: string;
  greenOnPositive?: boolean;
}

const ColoredValue = ({
  value,
  stockUom,
  greenOnPositive = true,
}: ColoredValueProps) => {
  const { i18n } = useTranslation();
  const valueString = value.toLocaleString(i18n.language, {
    maximumFractionDigits: 4,
    signDisplay: 'auto',
  });

  return (
    <Styled.Value
      isNegative={value < -0.001}
      greenOnPositive={greenOnPositive}
    >{`${valueString === '-0' ? '0' : valueString} ${stockUom}`}</Styled.Value>
  );
};

export { ColoredValue };
