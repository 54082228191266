import { Table } from 'antd';
import { RefineTableFilters } from 'common/refine-table-filters';
import { useTranslation } from 'react-i18next';

import { InvoiceFormContainer, InvoicePaymentForm } from '../invoice';
import { OrdersAndPaymentsEnum } from '../orders-and-payments.enum';
import { OrdersAndPaymentsTitle } from '../orders-and-payments-title.component';
import { PurchaseCreateContainer, PurchasePayment } from '../purchase';
import TotalColumn from '../purchase/columns/total-column/total-column.component';
import { useAccountsPayable } from './accounts-payable.hook';
import { AccountsPayableModel } from './accounts-payable.model';
import { AccountsPayableActions } from './accounts-payable-actions/accounts-payable-actions.component';
import { AccountsPayableCreateButton } from './accounts-payable-create-button/accounts-payable-create-button.component';
import { AccountsPayableDueDate } from './accounts-payable-due-date/accounts-payable-due-date.component';
import { AccountsPayableStatus } from './accounts-payable-status/accounts-payable-status.component';

const AccountsPayableContainer = () => {
  const { t } = useTranslation();

  const {
    viewDrawer,
    viewInvoiceDrawer,
    paymentInvoiceDrawer,
    paymentPurchaseDrawer,
    tableQuery,
    tableProps,
    listFilters,
    searchFormProps,
    getCurrencyByFarm,
    otherCurrencyById,
  } = useAccountsPayable();

  return (
    <>
      <InvoiceFormContainer drawerForm={viewInvoiceDrawer} />
      <PurchasePayment drawerForm={paymentPurchaseDrawer} />
      <PurchaseCreateContainer drawerForm={viewDrawer} isReadonly />
      <InvoicePaymentForm
        drawerForm={paymentInvoiceDrawer}
        refetchTable={tableQuery.refetch}
      />
      <OrdersAndPaymentsTitle
        selectedTab={OrdersAndPaymentsEnum.ACCOUNTS_PAYABLE}
        buttons={[
          <AccountsPayableCreateButton key="accounts-payable-create-button" />,
        ]}
      />
      <RefineTableFilters
        searchFormProps={searchFormProps}
        initialListFilters={listFilters}
      />
      <Table
        {...tableProps}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
        }}
      >
        <Table.Column
          dataIndex="dueDate"
          title={t('accounts_payable.table.due_date')}
          render={(_, accountsPayable: AccountsPayableModel) => (
            <AccountsPayableDueDate accountsPayable={accountsPayable} />
          )}
        />
        <Table.Column dataIndex="farm" title={t('common.business.farm')} />
        <Table.Column
          dataIndex="supplier"
          title={t('common.business.supplier')}
        />
        <Table.Column
          dataIndex="status"
          title={t('common.status')}
          render={(_, { status }: AccountsPayableModel) => (
            <AccountsPayableStatus status={status} />
          )}
        />
        <Table.Column
          dataIndex="paidAmount"
          title={t('accounts_payable.table.paid')}
          render={(
            _,
            {
              typeOtherCurrency,
              paidAmount,
              paidAmountOtherCurrency,
              isOtherCurrency,
              farm,
            }: AccountsPayableModel,
          ) => (
            <TotalColumn
              total={paidAmount}
              currency={getCurrencyByFarm(farm) ?? 'BRL'}
              otherCurrencyTotal={paidAmountOtherCurrency}
              isOtherCurrency={isOtherCurrency}
              otherCurrency={otherCurrencyById?.[typeOtherCurrency]}
            />
          )}
        />
        <Table.Column
          dataIndex="totalAmount"
          title={t('accounts_payable.table.total')}
          render={(
            _,
            {
              typeOtherCurrency,
              totalAmount,
              valueOtherCurrency,
              isOtherCurrency,
              farm,
            }: AccountsPayableModel,
          ) => (
            <TotalColumn
              total={totalAmount}
              currency={getCurrencyByFarm(farm) ?? 'BRL'}
              otherCurrencyTotal={valueOtherCurrency}
              isOtherCurrency={isOtherCurrency}
              otherCurrency={otherCurrencyById?.[typeOtherCurrency]}
            />
          )}
        />
        <Table.Column
          title={t('common.actions')}
          render={(_, accountsPayable: AccountsPayableModel) => (
            <AccountsPayableActions
              accountsPayable={accountsPayable}
              paymentInvoiceDrawer={paymentInvoiceDrawer}
              paymentPurchaseDrawer={paymentPurchaseDrawer}
              viewDrawer={viewDrawer}
              viewInvoiceDrawer={viewInvoiceDrawer}
            />
          )}
        />
      </Table>
    </>
  );
};

export { AccountsPayableContainer };
