import { Space, Typography } from 'antd';
import styled from 'styled-components';
import { BlueColors } from 'theme';

const IconWrapper = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 50px;
  background-color: ${BlueColors[10]};
  color: ${BlueColors[60]};
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 32px;
    height: 32px;
    fill: ${BlueColors[60]} !important;
  }
`;

const TypographyTitle = styled(Typography.Title)`
  margin: 0 !important;
`;

const TitleWrapper = styled(Space)`
  width: 100%;
`;

export { IconWrapper, TitleWrapper, TypographyTitle };
