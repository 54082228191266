import { Tag } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const ContainerFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  margin-bottom: 24px;
`;

const Separator = styled.div`
  width: 100%;
  border-bottom: 1px dashed ${NeutralColors[20]};
  margin: 24px 0px;
`;

const TestingTag = styled(Tag)`
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0px 8px;
  margin: 0;
  font-weight: bold;

  svg {
    margin-right: 4px;
    fill: ${NeutralColors[0]};
  }
`;

export { ContainerFlex, Separator, TestingTag };
