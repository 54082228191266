import { useDrawerForm } from '@refinedev/antd';
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useOne,
} from '@refinedev/core';
import { Button, Popconfirm, Skeleton, Space, Table } from 'antd';
import { EmptyState, PageTitle } from 'common/components';
import { ROUTES } from 'common/constants';
import { usePermissionsBalance } from 'common/hooks/use-permissions-balance.hook';
import { CompareArrowsIcon, EditListIcon, ItemsIcon } from 'common/icons';
import {
  ListFiltersModel,
  RefineTableFilters,
  RefineTableFiltersEnum,
  useRefineTableFilters,
} from 'common/refine-table-filters';
import { useNavigateWithOrgAndFarm, useTracking } from 'common/utils';
import { ActionRbacEnum } from 'common/utils/auth-provider.util';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useFarm, useOrganization } from 'src/organization';

import { AdjustmentStock } from '../adjustment-stock/adjustment-stock.container';
import { ColoredValue } from '../movements/colored-value/colored-value.component';
import { OpeningStockContainer, OpeningStockModel } from '../opening-stock';
import { SelectItemsMovements } from '../select-items-movements/select-items-movements.component';
import { IsGroupWarehouseEnum } from '../warehouse-list';
import { ColumnTitleWithTooltip } from './column-title-with-tooltip/column-title-with-tooltip.component';
import StockBalance from './stock-balance/stock-balance.component';
import { StockFilterModel, StockItemModel } from './stock-summary.model';
import * as Styled from './stock-summary.style';

const StockSummaryContainer: FC<IResourceComponentsProps> = () => {
  const { t } = useTranslation();
  const { track } = useTracking();
  const { farmName, warehouseId } = useParams();
  const { currentOrganizationId, isLoading: isOrganizationLoading } =
    useOrganization();
  const navigateWithOrgAndFarm = useNavigateWithOrgAndFarm();
  const { stockFms933InsertAdjustment } = useFlags();
  const { permissions } = usePermissionsBalance();
  const { currentFarmId, isLoading: isFarmLoading } = useFarm();
  const [itemAdjustment, setItemAdjustment] = useState<StockItemModel | null>(
    null,
  );

  const isDependenciesLoaded = useMemo(
    () =>
      (!!currentFarmId || !!currentOrganizationId) &&
      !isOrganizationLoading &&
      !isFarmLoading,
    [
      currentFarmId,
      currentOrganizationId,
      isFarmLoading,
      isOrganizationLoading,
    ],
  );

  const stockMetaData = useMemo(
    () => ({
      organizationId: currentOrganizationId,
      farm: farmName,
    }),
    [currentOrganizationId, farmName],
  );

  const defaultListFilters: ListFiltersModel[] = useMemo(
    () => [
      {
        label: t('filter.name'),
        field: 'itemCode',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'item',
        optionLabel: 'name',
        show: true,
        icon: <ItemsIcon />,
      },
      {
        label: t('filter.category'),
        field: 'category',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'item-category',
        show: true,
      },
    ],
    [t],
  );

  const { data: warehouseData } = useOne({
    resource: 'warehouse',
    id: warehouseId,
    queryOptions: {
      enabled: !!warehouseId,
    },
    meta: stockMetaData,
  });

  const listFiltersWithWarehouse: ListFiltersModel[] = useMemo(
    () => [
      ...defaultListFilters,
      {
        label: t('filter.warehouse'),
        field: 'warehouse',
        type: RefineTableFiltersEnum.SELECT,
        optionLabel: 'name',
        resource: 'warehouse',
        show: true,
        filters: [
          {
            field: 'farm',
            operator: 'eq',
            value: farmName,
          },
          {
            field: 'isGroup',
            operator: 'eq',
            value: IsGroupWarehouseEnum.NO,
          },
        ],
      },
    ],
    [defaultListFilters, farmName, t],
  );

  const initialFilters: CrudFilters = useMemo(
    () =>
      warehouseId
        ? [
            {
              field: 'warehouse',
              operator: 'eq',
              value: warehouseId,
            },
          ]
        : [],
    [warehouseId],
  );

  const { tableProps, searchFormProps, tableQuery, filters } =
    useRefineTableFilters<StockItemModel, HttpError, StockFilterModel>({
      listFilters: defaultListFilters,
      resource: 'stock/summary',
      syncWithLocation: true,
      queryOptions: {
        enabled: isDependenciesLoaded,
      },
      meta: stockMetaData,
      filters: {
        initial: initialFilters,
      },
      sorters: {
        initial: [
          {
            field: 'ACTUAL_QTY',
            order: 'desc',
          },
        ],
      },
      onSearch: (params) => {
        const filters: CrudFilters = [];

        const { itemCode, category, warehouse } = params;

        filters.push(
          {
            field: 'itemCode',
            operator: 'eq',
            value: itemCode,
          },
          {
            field: 'category',
            operator: 'eq',
            value: category,
          },
          {
            field: 'warehouse',
            operator: 'eq',
            value: warehouse ?? warehouseId,
          },
        );

        return filters;
      },
    });

  const filtersApplied = useMemo(
    () =>
      filters.length > initialFilters.length ||
      (!warehouseId && filters.length > 0),
    [filters.length, initialFilters.length, warehouseId],
  );

  const isLoadingData = useMemo(
    () =>
      ((tableQuery.isLoading || tableQuery.isFetching) && !tableQuery.data) ??
      true,
    [tableQuery.data, tableQuery.isFetching, tableQuery.isLoading],
  );

  const isEmpty = useMemo(
    () =>
      (isDependenciesLoaded &&
        tableQuery.data?.data.length === 0 &&
        !filtersApplied) ??
      false,
    [filtersApplied, isDependenciesLoaded, tableQuery.data],
  );

  const hasData = useMemo(
    () => !isLoadingData && !isEmpty,
    [isEmpty, isLoadingData],
  );

  const tableFilters = useMemo(
    () => (warehouseId ? defaultListFilters : listFiltersWithWarehouse),
    [defaultListFilters, listFiltersWithWarehouse, warehouseId],
  );

  const pageName = useMemo(
    () =>
      farmName || warehouseData?.data.name
        ? `- ${farmName ?? warehouseData?.data.name}`
        : undefined,
    [farmName, warehouseData],
  );

  const hasStockBalance = useMemo(() => {
    return (
      permissions?.includes(ActionRbacEnum.FINANCIAL_BALANCE_READ) &&
      (farmName || warehouseId)
    );
  }, [farmName, permissions, warehouseId]);

  const {
    drawerProps: openingStockDrawerProps,
    formProps: openingStockFormProps,
    saveButtonProps: openingStockSaveButtonProps,
    close: createClose,
    show: createShow,
  } = useDrawerForm<OpeningStockModel>({
    action: 'create',
    resource: 'stock-reconciliation',
    redirect: false,
    syncWithLocation: {
      key: 'opening-stock',
    },
  });

  const handleOpeningStock = () => {
    track('Start Opening Stock');
    createShow();
  };

  const handleCloseOpeningStock = () => {
    createClose();
    tableQuery.refetch();
  };

  const handleGoToMovements = useCallback(
    (itemCode: string, itemName: string) => {
      track('Go to Movements', { 'Item Code': itemCode });
      navigateWithOrgAndFarm(
        `${ROUTES.STOCK}/${ROUTES.MOVEMENTS_STOCK}`,
        undefined,
        undefined,
        undefined,
        {
          pageName,
          farm: farmName ?? warehouseData?.data.farm,
          warehouse: warehouseId,
          itemCode,
          itemName,
        },
      );
    },
    [farmName, navigateWithOrgAndFarm, pageName, warehouseId, track],
  );

  const handleOpenAdjustment = (initialAmount: StockItemModel) => {
    setItemAdjustment(initialAmount);
  };

  return (
    <Styled.StockSummaryWrapper>
      <AdjustmentStock
        item={itemAdjustment}
        setItem={setItemAdjustment}
        refetch={tableQuery.refetch}
      />
      <PageTitle
        title={t('stock_summary.title', { pageName })}
        hasBackButton
        buttons={
          hasStockBalance && (
            <StockBalance
              farmId={farmName ?? warehouseData?.data.farm}
              warehouseId={
                warehouseId ?? searchFormProps?.form?.getFieldValue('warehouse')
              }
            />
          )
        }
      />
      {isLoadingData && <Skeleton active data-testid="loading-skeleton" />}
      {hasData && (
        <>
          <RefineTableFilters
            searchFormProps={searchFormProps}
            initialListFilters={tableFilters}
            leftItems={
              <SelectItemsMovements
                pageName={pageName}
                farm={farmName ?? warehouseData?.data.farm}
                warehouse={warehouseId}
              />
            }
          />
          <Table
            {...tableProps}
            pagination={{
              ...tableProps.pagination,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 50, 100],
            }}
            rowKey={(record) => `${record.warehouseId}-${record.itemCode}`}
          >
            {!farmName && (
              <Table.Column
                dataIndex="farm"
                title={t('stock.table.farm')}
                width="20%"
              />
            )}
            {!warehouseId && (
              <Table.Column
                dataIndex="warehouseName"
                title={t('stock.table.warehouse')}
                width="20%"
              />
            )}
            <Table.Column
              dataIndex="NAME"
              title={t('stock.table.name')}
              render={(_, { itemName }: StockItemModel) => itemName}
              sorter
            />
            <Table.Column
              dataIndex="ACTUAL_QTY"
              title={t('stock.table.actual_qty')}
              width="15%"
              render={(_, { actualQty, stockUom }: StockItemModel) => (
                <ColoredValue
                  stockUom={stockUom}
                  value={actualQty}
                  greenOnPositive={false}
                />
              )}
              sorter
            />
            <Table.Column
              width="15%"
              dataIndex="WAITED_QTY"
              render={(_, { waitedQty, stockUom }: StockItemModel) => (
                <ColoredValue
                  stockUom={stockUom}
                  value={waitedQty}
                  greenOnPositive={false}
                />
              )}
              title={
                <ColumnTitleWithTooltip
                  title={t('stock.table.waited_qty')}
                  tooltip={t('stock.table.waited_qty_tooltip')}
                />
              }
              sorter
            />
            {permissions?.includes(ActionRbacEnum.FINANCIAL_BALANCE_WRITE) && (
              <Table.Column
                title={t('stock.table.actions')}
                align="center"
                render={(stockItem, { itemCode, itemName }: StockItemModel) => (
                  <Space>
                    <Styled.CrossingArrowsButton
                      icon={<CompareArrowsIcon />}
                      onClick={() => handleGoToMovements(itemCode, itemName)}
                    />
                    {stockFms933InsertAdjustment && (
                      <Popconfirm
                        title={t('stock.table.adjustment_title')}
                        description={t('stock.table.adjustment_description')}
                        onConfirm={() => handleOpenAdjustment(stockItem)}
                        okText={t('buttons.continue')}
                        cancelText={t('buttons.cancel')}
                      >
                        <Styled.CrossingArrowsButton icon={<EditListIcon />} />
                      </Popconfirm>
                    )}
                  </Space>
                )}
              />
            )}
          </Table>
        </>
      )}
      {isEmpty && (
        <>
          <EmptyState
            title={t('stock.empty_state.title')}
            description={`${t('stock.empty_state.description')}`}
          >
            <Button type="primary" onClick={handleOpeningStock}>
              {t('stock.empty_state.button')}
            </Button>
          </EmptyState>
          <OpeningStockContainer
            drawerProps={openingStockDrawerProps}
            formProps={openingStockFormProps}
            onClose={handleCloseOpeningStock}
            onOpenStock={openingStockSaveButtonProps}
            warehouseId={warehouseId}
            farmId={farmName}
          />
        </>
      )}
    </Styled.StockSummaryWrapper>
  );
};

export { StockSummaryContainer as default, StockSummaryContainer };
