import { Button, Layout } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const Sider = styled(Layout.Sider)`
  &,
  .fms-layout-sider-trigger {
    border-right: solid 1px ${NeutralColors[90]};
  }

  .fms-layout-sider-children {
    background-color: ${NeutralColors[90]};
    padding-bottom: 24px;
    overflow: auto;
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  .fms-layout-sider {
    overflow: hidden;
  }

  .fms-menu-item-group-title {
    color: ${NeutralColors[60]};
    border-right: solid 1px ${NeutralColors[90]};
  }

  .fms-layout-sider-children {
    background-color: ${NeutralColors[90]};
    padding-bottom: 24px;
    overflow: auto;
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  .fms-layout-sider {
    overflow: hidden;
  }

  .fms-menu-item-group-title {
    color: ${NeutralColors[60]};
  }

  .fms-menu {
    margin-top: 8px;
    border-inline-end-width: 0 !important;
    background-color: ${NeutralColors[90]};
  }

  .fms-menu-submenu {
    border-radius: 0 !important;

    &-title {
      border: none;
      border-radius: 0;
      margin: 0 !important;
      width: 100%;
      display: flex;
      align-items: center;
      color: ${NeutralColors[60]};

      :hover,
      :active {
        background-color: ${NeutralColors[100]} !important;
        color: ${NeutralColors[0]} !important;
      }

      svg {
        flex-grow: 0;
        flex-shrink: 0;
        fill: currentColor !important;
      }
    }

    &-selected {
      .fms-menu-submenu-title {
        background-color: ${NeutralColors[100]};
        color: ${NeutralColors[0]};

        ::before {
          content: '';
          background-color: ${NeutralColors[0]};
          border-radius: 0 4px 4px 0;
          width: 4px;
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          height: calc(100% - 8px);
        }
      }
    }

    .fms-menu {
      margin: 0 !important;
    }
  }

  .fms-menu-item {
    border: none;
    border-radius: 0;
    margin: 0 !important;
    width: 100%;
    display: flex;
    align-items: center;
    color: ${NeutralColors[60]};

    :hover,
    :active {
      background-color: ${NeutralColors[100]} !important;
      color: ${NeutralColors[0]} !important;
    }

    svg {
      flex-grow: 0;
      flex-shrink: 0;
      fill: currentColor !important;
    }
  }

  .fms-menu-item-selected {
    background-color: ${NeutralColors[100]};
    color: ${NeutralColors[0]};

    ::before {
      content: '';
      background-color: ${NeutralColors[0]};
      border-radius: 0 4px 4px 0;
      width: 4px;
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: calc(100% - 8px);
    }
  }

  .fms-layout-sider-trigger {
    background-color: ${NeutralColors[100]} !important;
    color: ${NeutralColors[60]} !important;
  }
`;

const Version = styled.span`
  position: absolute;
  left: 16px;
  bottom: 50px;
  font-size: 12px;
  color: ${NeutralColors[60]};
`;

const QuickActionsButton = styled(Button)`
  margin-top: 24px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 40px;
  border-radius: 50% !important;
  background-color: ${NeutralColors[90]} !important;
  border-color: ${NeutralColors[60]} !important;

  display: flex;
  align-items: center;
  justify-content: center;
  color: ${NeutralColors[60]} !important;

  :hover {
    background-color: ${NeutralColors[100]} !important;
  }

  &.full-width {
    width: 240px;
    border-radius: 12px !important;
  }

  svg {
    height: 24px;
    width: 24px;
    fill: currentColor;
  }
`;

export { QuickActionsButton, Sider, Version };
