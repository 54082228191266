import { useOne } from '@refinedev/core';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useSelectBalance } from 'common/hooks/use-select-balance.hook';
import { FarmModel } from 'common/models';
import { useEffect, useMemo } from 'react';
import { useOrganization } from 'src/organization';
import { BankAccountModel } from 'src/resources/bank-account/bank-account.model';

import { usePurchaseCreateStore } from '../purchase-create.store';

const DEFAULT_PARENT_COST_CENTER = 'Produção Agrícola';

const usePurchaseCreateDetails = () => {
  const { currentOrganizationId, entitlements } = useOrganization();

  const { setCurrency } = usePurchaseCreateStore();

  const formInstance = useFormInstance();
  const farmName = useWatch('farmName');
  const transactionDate = useWatch('transactionDate');
  const scheduleDate = useWatch('scheduleDate');
  const isPaid = useWatch('isPaid');
  const paymentAccount = useWatch('paymentAccount');

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  useEffect(() => {
    formInstance?.validateFields(['transactionDate', 'scheduleDate']).then(
      () => undefined,
      () => undefined,
    );
  }, [transactionDate, scheduleDate, formInstance]);

  const { data: existsCustomData } = useOne({
    resource: 'account/custom/exists-custom',
    id: farmName,
    queryOptions: {
      enabled: !!metadata.organizationId && isPaid && !!farmName,
    },
  });

  const { selectProps: accountSelectProps, query: accountQueryResult } =
    useSelectBalance<BankAccountModel>({
      resource: 'account',
      optionLabel: 'name',
      optionValue: 'id',
      onSearch: (value) => [
        {
          field: 'name',
          operator: 'eq',
          value: value,
        },
      ],
      filters: [
        {
          field: 'farm',
          operator: 'eq',
          value: farmName,
        },
        {
          field: 'accountType',
          operator: 'eq',
          value: ['Bank', 'Cash'],
        },
        {
          field: 'reportType',
          operator: 'eq',
          value: 'Balance Sheet',
        },
        {
          field: 'isGroup',
          operator: 'eq',
          value: 0,
        },
        {
          field: 'isDisabled',
          operator: 'eq',
          value: 0,
        },
      ],
      queryOptions: {
        enabled: !!metadata.organizationId && isPaid && !!farmName,
      },
    });

  const { selectProps: supplierSelectProps } = useSelectBalance({
    resource: 'supplier',
    filters: [
      {
        field: 'disabled',
        operator: 'eq',
        value: false,
      },
    ],
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    meta: metadata,
    queryOptions: {
      enabled: !!metadata.organizationId,
    },
    pagination: {
      pageSize: 50,
    },
  });

  const { selectProps: farmSelectProps } = useSelectBalance({
    resource: 'farm',
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    pagination: {
      pageSize: 50,
    },
    meta: metadata,
    queryOptions: {
      enabled: !!metadata.organizationId,
    },
  });

  const { selectProps: costCenterSelectProps } = useSelectBalance({
    resource: 'cost-center',
    optionLabel: 'costCenterName',
    optionValue: 'id',
    filters: [
      {
        field: 'parentCostCenter',
        operator: 'eq',
        value: DEFAULT_PARENT_COST_CENTER,
      },
      {
        field: 'farm',
        operator: 'eq',
        value: farmName,
      },
    ],
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    meta: metadata,
    queryOptions: {
      enabled: !!metadata.organizationId,
    },
  });

  const { data: farmData } = useOne<FarmModel>({
    resource: 'farm',
    id: farmName,
    queryOptions: {
      enabled: !!farmName,
    },
  });

  useEffect(() => {
    if (farmData?.data?.currency) {
      setCurrency(farmData.data.currency);
    }
  }, [farmData, setCurrency]);

  useEffect(() => {
    formInstance?.setFieldValue('paymentAccount', undefined);
    formInstance?.setFieldValue('costCenter', undefined);
  }, [farmName]);

  useEffect(() => {
    if (accountQueryResult?.data?.data) {
      formInstance?.setFieldValue(
        'paymentAccountType',
        accountQueryResult.data.data.find(
          (bankAccount) => bankAccount.id === paymentAccount,
        )?.type,
      );
    }
  }, [formInstance?.setFieldValue, accountQueryResult?.data, paymentAccount]);

  return {
    entitlements,
    farmName,
    scheduleDate,
    supplierSelectProps,
    farmSelectProps,
    costCenterSelectProps,
    isPaid,
    hasCustomAccount: existsCustomData?.data,
    accountSelectProps,
  };
};

export { usePurchaseCreateDetails as default, usePurchaseCreateDetails };
