import {
  useSelect as refineUseSelect,
  UseSelectReturnType,
} from '@refinedev/antd';
import {
  BaseOption,
  BaseRecord,
  HttpError,
  UseSelectProps,
} from '@refinedev/core';
import { NotFoundContentLoading } from 'common/components/selects/not-found-content-loading/not-found-content-loading.component';
import { useEffect, useState } from 'react';

const useSelectBalance = <
  TQueryFnData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TData extends BaseRecord = TQueryFnData,
  TOption extends BaseOption = BaseOption,
>(
  props: UseSelectProps<TQueryFnData, TError, TData>,
): UseSelectReturnType<TData, TOption> => {
  const select = refineUseSelect<TQueryFnData, TError, TData, TOption>({
    ...props,
    debounce: 300,
    pagination: {
      pageSize: 50,
      ...props.pagination,
    },
  });

  const isLoading = select?.query?.isLoading || select?.query?.isFetching;

  const [delayedLoading, setDelayedLoading] = useState(isLoading);

  useEffect(() => {
    if (!isLoading) {
      const timer = setTimeout(() => {
        setDelayedLoading(false);
      }, 600);

      return () => clearTimeout(timer);
    } else {
      setDelayedLoading(true);
    }
  }, [isLoading]);

  return {
    ...select,
    selectProps: {
      ...select.selectProps,
      options: delayedLoading ? [] : select?.selectProps?.options,
      loading: delayedLoading,
      notFoundContent: <NotFoundContentLoading loading={delayedLoading} />,
    },
  };
};

export { useSelectBalance };
