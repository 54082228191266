import { Empty, Spin } from 'antd';

import { NotFoundContentLoadingTestId } from './not-found-content-loading.constant';
import * as Styled from './not-found-content-loading.style';

interface NotFoundContentLoadingProps {
  loading?: boolean;
}

const NotFoundContentLoading = ({
  loading = false,
}: NotFoundContentLoadingProps) => {
  const renderLoading = () => (
    <Styled.SpinWrapper data-testid={NotFoundContentLoadingTestId.SPIN_WRAPPER}>
      <Spin />
    </Styled.SpinWrapper>
  );

  const renderEmpty = () => (
    <Empty data-testid={NotFoundContentLoadingTestId.EMPTY} />
  );

  return loading ? renderLoading() : renderEmpty();
};

export { NotFoundContentLoading };
