import { track } from '@amplitude/analytics-browser';
import { ComputedDatum } from '@nivo/pie';
import { useDrawerForm } from '@refinedev/antd';
import {
  BaseRecord,
  HttpError,
  useApiUrl,
  useCustom,
  useOne,
} from '@refinedev/core';
import { useWatch } from 'antd/es/form/Form';
import { ChartDataModel } from 'common/charts/charts.model';
import { CategoryIcon, CropIcon, DateIcon, FarmIcon } from 'common/icons';
import { FarmModel } from 'common/models';
import {
  ListFiltersModel,
  RefineTableFiltersEnum,
  useRefineTableFilters,
} from 'common/refine-table-filters';
import { CurrencyCode, getCurrencySymbol } from 'common/utils';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MaterialConsumptionRequestModel } from 'src/material-consumption/material-consumption.model';
import { useFarm, useOrganization } from 'src/organization';

import { SeasonModel } from '../financial.model';
import { ObjectiveModel } from '../objective/form/objective-form.model';
import { useReferenceValue } from '../reference-value/reference-value.hook';
import { useReferenceValueStore } from '../reference-value/reference-value.store';
import {
  DashboardCostVisionCategoryModel,
  DashboardField,
} from './financial-overview.model';

interface FilterFormModel {
  farm: string;
  seasonId: string;
  itemCategoryId: string;
  project: string;
}

const useFinancialOverview = () => {
  const [objectiveDrawerOpenKey, setObjectiveDrawerOpenKey] =
    useState<string>();
  const apiUrl = useApiUrl();
  const { currentOrganizationId, entitlements } = useOrganization();
  const { t } = useTranslation();
  const { toggleReferenceValue, finalProduct } = useReferenceValueStore();

  const { currentFarm } = useFarm();

  const listFilters: ListFiltersModel[] = useMemo(
    () => [
      {
        label: t('common.business.farm'),
        field: 'farm',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'farm',
        initialValue: currentFarm?.name,
        show: true,
        icon: <FarmIcon />,
      },
      {
        label: t('common.business.season'),
        optionLabel: 'seasonName',
        optionValue: 'seasonId',
        field: 'seasonId',
        parentsField: ['farm'],
        type: RefineTableFiltersEnum.SELECT,
        resource: 'season',
        filters: [
          {
            field: 'deleted',
            operator: 'eq',
            value: 'false',
          },
        ],
        show: true,
        icon: <DateIcon />,
      },
      {
        label: t('common.business.field'),
        field: 'project',
        type: RefineTableFiltersEnum.MULTIPLE_SELECT,
        resource: 'project',
        parentsField: ['farm', 'seasonId'],
        icon: <CropIcon />,
        show: true,
        optionLabel: 'projectName',
      },
      {
        label: t('filter.category'),
        field: 'itemCategoryId',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'item-category',
        show: true,
        icon: <CategoryIcon />,
      },
    ],
    [currentFarm?.name, t],
  );

  const { searchFormProps } = useRefineTableFilters<
    DashboardCostVisionCategoryModel,
    HttpError,
    FilterFormModel
  >({
    listFilters,
    resource: 'dashboard/cost-vision/category',
    syncWithLocation: false,
    storeName: 'financial',
    queryOptions: {
      enabled: false,
    },
  });

  const filterValues = useWatch([], searchFormProps);

  const {
    handleOnReferenceValueClick,
    referenceValueDrawerProps,
    finalProductRefetch,
  } = useReferenceValue({
    seasonId: filterValues?.seasonId,
    farm: filterValues?.farm,
  });

  const {
    data: chartData,
    isLoading: isChartLoading,
    refetch: chartDataRefetch,
  } = useOne<DashboardField>({
    resource: 'dashboard/cost-vision',
    id: filterValues?.farm && filterValues?.seasonId ? 'field' : undefined,
    meta: {
      params: {
        organizationId: currentOrganizationId,
        farm: filterValues?.farm,
        seasonId: filterValues?.seasonId,
        itemCategoryId: filterValues?.itemCategoryId,
        project: filterValues?.project,
        valueReference: toggleReferenceValue
          ? finalProduct?.valueReference
          : undefined,
      },
    },
  });

  const { data: seasonLargerAreaData, isFetched } = useOne<SeasonModel>({
    resource: 'season/largest-area',
    id: filterValues?.farm,
    meta: {
      params: {
        organizationId: currentOrganizationId,
      },
    },
  });

  useEffect(() => {
    if (
      seasonLargerAreaData?.data &&
      isFetched &&
      filterValues?.seasonId === undefined
    ) {
      searchFormProps.form?.setFieldValue(
        'seasonId',
        seasonLargerAreaData.data.seasonId,
      );
    }
  }, [
    searchFormProps?.form?.setFieldValue,
    seasonLargerAreaData?.data,
    isFetched,
  ]);

  const { data: farmData } = useOne<FarmModel>({
    resource: 'farm',
    id: filterValues?.farm,
    meta: {
      organizationId: currentOrganizationId,
    },
    queryOptions: {
      enabled: !!filterValues?.farm && !!currentOrganizationId,
      retry: false,
    },
    errorNotification: false,
  });

  const selectedFarm = farmData?.data;

  const {
    data: chartByCategoryData,
    isLoading: isChartByCategoryLoading,
    isFetching: isChartByCategoryFetching,
  } = useCustom<DashboardCostVisionCategoryModel>({
    dataProviderName: 'simpleRest',
    url: `${apiUrl}/dashboard/cost-vision/category`,
    method: 'get',
    queryOptions: {
      enabled: !!filterValues?.seasonId && !!filterValues?.farm,
      retry: false,
    },
    config: {
      query: {
        seasonId: filterValues?.seasonId,
        farm: filterValues?.farm,
        itemCategoryId: filterValues?.itemCategoryId,
        project: filterValues?.project,
        organizationId: currentOrganizationId,
        valueReference: toggleReferenceValue
          ? finalProduct?.valueReference
          : undefined,
      },
    },
  });

  const handleCategoryClick = (category: ComputedDatum<ChartDataModel>) => {
    searchFormProps.form?.setFieldValue('itemCategoryId', category.id);
  };

  const needInitialFilters: boolean = useMemo(
    () => !filterValues?.farm || !filterValues?.seasonId,
    [filterValues?.farm, filterValues?.seasonId],
  );

  const isChartByCategoryLoadingData: boolean = useMemo(
    () =>
      (isChartByCategoryLoading || isChartByCategoryFetching) &&
      !needInitialFilters,
    [isChartByCategoryLoading, isChartByCategoryFetching, needInitialFilters],
  );

  const currency: CurrencyCode = useMemo(
    () => (selectedFarm?.currency ?? 'BRL') as CurrencyCode,
    [selectedFarm?.currency],
  );

  const currencySymbol: string = useMemo(
    () => getCurrencySymbol(currency),
    [currency],
  );

  const insertProductionDrawerForm = useDrawerForm<
    BaseRecord,
    HttpError,
    MaterialConsumptionRequestModel
  >({
    action: 'create',
    resource: 'stock-entry',
    redirect: false,
    syncWithLocation: {
      key: 'create',
    },
  });

  const objectiveDrawerForm = useDrawerForm<
    BaseRecord,
    HttpError,
    ObjectiveModel
  >({
    action: 'create',
    resource: 'project/estimated-value',
    redirect: false,
    syncWithLocation: {
      key: 'objective',
    },
    warnWhenUnsavedChanges: false,
  });

  const handleOpenInsertProductionDrawer = () => {
    track('Open Insert Production Drawer');
    insertProductionDrawerForm.show();
  };

  const handleOpenObjectiveDrawer = (openKey: string) => {
    track('Open Objective Drawer - ' + openKey);
    objectiveDrawerForm.show();
    setObjectiveDrawerOpenKey(openKey);
  };

  return {
    entitlements,
    insertProductionDrawerForm,
    handleOpenInsertProductionDrawer,
    finalProduct,
    finalProductRefetch,
    toggleReferenceValue,
    handleOnReferenceValueClick,
    referenceValueDrawerProps,
    filterValues,
    listFilters,
    handleCategoryClick,
    searchFormProps,
    needInitialFilters,
    chartData,
    isChartLoading,
    isChartByCategoryLoadingData,
    chartByCategoryData,
    isChartByCategoryLoading,
    isChartByCategoryFetching,
    currency,
    currencySymbol,
    chartDataRefetch,
    objectiveDrawerForm,
    objectiveDrawerOpenKey,
    handleOpenObjectiveDrawer,
  };
};

export { useFinancialOverview };
