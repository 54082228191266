import * as Amplitude from '@amplitude/analytics-browser';
import { initCWElements } from '@cw-elements/config';
import { initMfes } from '@cw-elements/mfe-setup/init';
import * as Sentry from '@sentry/react';
import {
  hasSmartlookKey,
  isProduction,
  TRACKING_KEY,
  TRACKING_OPTIONS,
} from 'common/utils';
import * as ReactDOM from 'react-dom/client';
import Smartlook from 'smartlook-client';

import AppContainer from './app.container';
import CW_ELEMENTS_CONFIG, {
  CW_ELEMENTS_ENV,
  CW_ELEMENTS_URL,
} from './cw-elements.config';
import { SENTRY_CONFIG, SENTRY_KEY } from './sentry.config';

if (SENTRY_KEY) {
  Sentry.init(SENTRY_CONFIG);
}

if (CW_ELEMENTS_ENV) {
  initCWElements(CW_ELEMENTS_CONFIG);
}

(async () => {
  await initMfes({
    'ag-operation': `${CW_ELEMENTS_URL}/ag-operation`,
  });
  await import('./main');
})();

if (TRACKING_KEY) {
  Amplitude.init(TRACKING_KEY, TRACKING_OPTIONS);
}

if (isProduction && hasSmartlookKey) {
  Smartlook.init(import.meta.env.VITE_SMARTLOOK_KEY, {
    interceptors: {
      error: (data) => {
        if (data.message?.startsWith('Failed to load IMG')) {
          return false;
        }
      },
    },
  });
  Smartlook.record({ forms: true, numbers: true });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(<AppContainer />);
