import { track } from '@amplitude/analytics-browser';
import { Space, Tooltip, Typography } from 'antd';
import { CardButton, PageTitle } from 'common/components';
import { ROUTES } from 'common/constants';
import {
  BarcodeIcon,
  BoxIcon,
  CategoryIcon,
  ItemsIcon,
  ResourcesIcon,
  SupplierIcon,
  TestingIcon,
  UploadIcon,
  WorkOutlineIcon,
} from 'common/icons';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BlueColors,
  GreenColors,
  RedColors,
  TealColors,
  YellowColors,
} from 'theme';

import { ButtonResource } from './button-resource/button-resource.container';
import CustomReports from './custom-reports/custom-reports.component';
import { DataExport } from './data-export/data-export.component';
import { CardTesting } from './data-import/card-testing/card-testing.component';
import GroupTitleComponent from './group-title/group-title.component';
import { ReportsTestId } from './reports.constant';
import { DocTypeEnum, GroupDocTypeEnum } from './reports.enum';
import * as Styled from './reports.style';

const ReportsContainer = () => {
  const { t } = useTranslation();
  const [docType, setDocType] = useState<
    DocTypeEnum | GroupDocTypeEnum | undefined
  >();
  const { dataImportFms633StartImport, dataImportFms636ListResources } =
    useFlags();
  const navigate = useNavigateWithOrgAndFarm();

  const handleOnClickExport = (docType: DocTypeEnum | GroupDocTypeEnum) => {
    setDocType(docType);
    track('Reports - Click Report Drawer', { docType });
  };

  const handleGoToDataImportStart = () => navigate(ROUTES.REPORTS_START);

  return (
    <>
      <PageTitle title={t('reports.title')} />
      <DataExport docType={docType} setDocType={setDocType} />
      <CustomReports />
      <GroupTitleComponent
        title={t('reports.management.title')}
        description={t('reports.management.description')}
        icon={<WorkOutlineIcon />}
      />
      <Styled.ContainerFlex>
        <CardButton
          icon={<CategoryIcon />}
          title={t('reports.management.all_registers')}
          data-testid={ReportsTestId.REPORTS_EXPORT_CARD}
          onClick={() => handleOnClickExport(GroupDocTypeEnum.MANAGEMENT)}
          iconColor={GreenColors}
        />
        <CardButton
          icon={<BoxIcon />}
          title={t('reports.management.purchase_orders')}
          data-testid={ReportsTestId.REPORTS_EXPORT_CARD}
          onClick={() => handleOnClickExport(DocTypeEnum.PURCHASE_ORDER)}
          iconColor={RedColors}
        />
        <CardButton
          icon={<BarcodeIcon />}
          title={t('reports.management.purchase_invoices')}
          data-testid={ReportsTestId.REPORTS_EXPORT_CARD}
          onClick={() => handleOnClickExport(DocTypeEnum.PURCHASE_INVOICE)}
          iconColor={YellowColors}
        />
      </Styled.ContainerFlex>
      <GroupTitleComponent
        title={t('reports.resources.title')}
        description={t('reports.resources.description')}
        icon={<ResourcesIcon />}
      />
      <Styled.ContainerFlex>
        <CardButton
          icon={<ResourcesIcon />}
          title={t('reports.resources.all_registers')}
          data-testid={ReportsTestId.REPORTS_EXPORT_CARD}
          onClick={() => handleOnClickExport(GroupDocTypeEnum.RESOURCES)}
          iconColor={GreenColors}
        />
        <CardButton
          icon={<ItemsIcon />}
          title={t('reports.resources.items')}
          data-testid={ReportsTestId.REPORTS_EXPORT_CARD}
          onClick={() => handleOnClickExport(DocTypeEnum.ITEM)}
          iconColor={RedColors}
        />
        <CardButton
          icon={<CategoryIcon />}
          title={t('reports.resources.item_category')}
          data-testid={ReportsTestId.REPORTS_EXPORT_CARD}
          onClick={() => handleOnClickExport(DocTypeEnum.ITEM_GROUP)}
          iconColor={YellowColors}
        />
        <CardButton
          icon={<SupplierIcon />}
          title={t('reports.resources.suppliers')}
          data-testid={ReportsTestId.REPORTS_EXPORT_CARD}
          onClick={() => handleOnClickExport(DocTypeEnum.SUPPLIER)}
          iconColor={TealColors}
        />
      </Styled.ContainerFlex>
      <GroupTitleComponent
        title={t('reports.imported_data.title')}
        description={
          <Space>
            <Styled.TestingTag icon={<TestingIcon />} color={BlueColors[60]}>
              {t('common.testing').toUpperCase()}
            </Styled.TestingTag>
            {t('reports.imported_data.description')}
          </Space>
        }
        icon={<UploadIcon />}
      />
      {dataImportFms636ListResources && (
        <Space direction="vertical" size={16}>
          {dataImportFms633StartImport && (
            <Tooltip
              placement="bottom"
              color={BlueColors[10]}
              title={<CardTesting />}
            >
              <CardButton
                icon={<UploadIcon />}
                title={t('reports.imported_data.import')}
                data-testid={ReportsTestId.REPORTS_CARD_START}
                onClick={handleGoToDataImportStart}
                iconColor={BlueColors}
              />
            </Tooltip>
          )}
          <Typography.Text strong type="secondary">
            {t('reports.imported_data.imported_and_exported_recently')}
          </Typography.Text>
          <Styled.ContainerFlex
            data-testid={ReportsTestId.REPORTS_BUTTON_RESOURCE}
          >
            {Object.values(DocTypeEnum).map((value) => (
              <ButtonResource
                key={`button-resource-${value}`}
                docType={value}
              />
            ))}
          </Styled.ContainerFlex>
        </Space>
      )}
    </>
  );
};

export default ReportsContainer;
