import { useOne } from '@refinedev/core';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useSelectBalance } from 'common/hooks/use-select-balance.hook';
import { useEffect, useMemo } from 'react';
import { useOrganization } from 'src/organization';

const useReferenceValueFormDetail = (farm?: string, season?: string) => {
  const { currentOrganizationId } = useOrganization();
  const form = useFormInstance();
  const selectedSeasonId = useWatch(['seasonId']);
  const FormInstance = useFormInstance();

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const { selectProps: seasonSelectProps } = useSelectBalance({
    resource: 'season',
    optionLabel: 'seasonName',
    optionValue: 'seasonId',
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
      {
        field: 'deleted',
        operator: 'eq',
        value: 'false',
      },
    ],
    filters: [
      {
        field: 'farm',
        operator: 'eq',
        value: farm,
      },
    ],
    pagination: {
      pageSize: 50,
    },
    meta: metadata,
  });

  useEffect(() => {
    FormInstance.setFieldValue('seasonId', season);
  }, [season]);

  const { data: seasonData } = useOne({
    resource: 'season',
    id: selectedSeasonId,
    meta: {
      params: {
        farm,
      },
    },
    queryOptions: {
      enabled: !!selectedSeasonId,
    },
  });

  useEffect(() => {
    if (seasonData?.data?.crop) {
      form.setFieldsValue({
        cropId: seasonData?.data.crop,
      });
    }
  }, [seasonSelectProps.options, form, seasonData?.data.crop]);

  return {
    seasonSelectProps,
  };
};

export { useReferenceValueFormDetail };
