import { useDrawerForm } from '@refinedev/antd';
import { HttpError, useOne } from '@refinedev/core';
import { Form } from 'antd';
import { useSelectBalance } from 'common/hooks/use-select-balance.hook';
import { useTracking } from 'common/utils';
import { KeyboardEvent, MouseEvent, useEffect, useMemo, useState } from 'react';
import { SeasonModel } from 'src/financial/financial.model';
import { useOrganization } from 'src/organization';

import { StockItemModel } from '../stock-summary/stock-summary.model';
import {
  AdjustmentStockModel,
  ItemStockComparison,
  StockComparison,
} from './adjustment-stock.model';

interface UseAdjustmentStockProps {
  item: StockItemModel | null;
  setItem: (x: StockItemModel | null) => void;
  refetch: () => void;
}

const useAdjustmentStock = ({
  item,
  setItem,
  refetch,
}: UseAdjustmentStockProps) => {
  const { currentOrganizationId } = useOrganization();
  const { track } = useTracking();
  const [stockComparison, setStockComparison] = useState<
    StockComparison | undefined
  >(undefined);
  const [itemStockComparison, setItemStockComparison] = useState<
    ItemStockComparison | undefined
  >(undefined);

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const {
    formLoading: saveLoading,
    drawerProps: createDrawerProps,
    show: createShow,
    formProps,
    saveButtonProps,
  } = useDrawerForm<AdjustmentStockModel, HttpError, AdjustmentStockModel>({
    action: 'create',
    redirect: false,
    resource: 'stock-adjust',
    meta: metadata,
  });

  const { data: dataStockComparison, isFetching } = useOne<StockComparison[]>({
    id: 'stock-comparison',
    meta: {
      ...metadata,
      params: {
        itemCode: item?.itemCode,
        warehouse: item?.warehouseId,
      },
    },
  });

  const { selectProps: seasonSelectProps } = useSelectBalance<SeasonModel>({
    resource: 'season',
    optionValue: 'seasonId',
    optionLabel: 'seasonName',
    filters: [
      {
        field: 'farm',
        operator: 'eq',
        value: stockComparison?.farm,
      },
      {
        field: 'deleted',
        operator: 'eq',
        value: 'false',
      },
    ],
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    meta: metadata,
    queryOptions: {
      enabled: !!stockComparison?.farm,
    },
  });

  const formValues = Form.useWatch<AdjustmentStockModel>([], formProps.form);

  useEffect(() => {
    if (item != null) {
      createShow();
      formProps.form.setFieldsValue({
        newQty: item?.actualQty,
      });
    }
  }, [item]);

  const handleOnClose = (e: MouseEvent | KeyboardEvent) => {
    setItem(null);
    if (createDrawerProps.onClose) {
      createDrawerProps.onClose(e);
    }
  };

  const handleOnFinish = (values: AdjustmentStockModel) => {
    if (formProps.onFinish) {
      track('Save adjustment stock');
      formProps.onFinish({
        ...values,
        farm: stockComparison?.farm ?? '',
        warehouse: stockComparison?.warehouseId ?? '',
        itemCode: item?.itemCode ?? '',
        actualQty: itemStockComparison?.fmsBalanceQty ?? 0,
      });
    }
  };

  const handleOnClearStock = () => {
    formProps.form.setFieldValue('newQty', 0);
  };

  useEffect(() => {
    if (dataStockComparison?.data && dataStockComparison.data.length > 0) {
      setStockComparison(dataStockComparison?.data[0]);
      if (dataStockComparison?.data[0]?.items?.length > 0) {
        setItemStockComparison(dataStockComparison.data[0].items[0]);
      }
    } else {
      setStockComparison(undefined);
      setItemStockComparison(undefined);
    }
  }, [dataStockComparison]);

  useEffect(() => {
    if (!createDrawerProps.open) {
      refetch();
    }
  }, [createDrawerProps.open]);

  return {
    createDrawerProps,
    formProps,
    formValues,
    isFetching,
    itemStockComparison,
    saveButtonProps,
    saveLoading,
    seasonSelectProps,
    handleOnClose,
    handleOnClearStock,
    handleOnFinish,
  };
};

export { useAdjustmentStock };
