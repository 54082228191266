import { useWatch } from 'antd/es/form/Form';
import { useSelectBalance } from 'common/hooks/use-select-balance.hook';
import { useMemo } from 'react';
import { useOrganization } from 'src/organization';
import { IsGroupWarehouseEnum } from 'src/stock/warehouse-list';

const useStockTransferDetails = () => {
  const sourceFarm = useWatch('sourceFarm');
  const targetFarm = useWatch('targetFarm');
  const transferBetweenFarms = useWatch('transferBetweenFarms');

  const { currentOrganizationId, isLoading: isOrganizationLoading } =
    useOrganization();

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const loadSourceWarehousesList = useMemo(
    () =>
      Boolean(!!currentOrganizationId && !isOrganizationLoading && sourceFarm),
    [currentOrganizationId, sourceFarm, isOrganizationLoading],
  );

  const loadTargetWarehousesList = useMemo(
    () =>
      Boolean(!!currentOrganizationId && !isOrganizationLoading && targetFarm),
    [currentOrganizationId, targetFarm, isOrganizationLoading],
  );

  const {
    selectProps: sourceWarehouseSelectProps,
    query: sourceWarehouseQueryResult,
  } = useSelectBalance({
    resource: 'warehouse',
    optionValue: 'id',
    optionLabel: 'id',
    filters: [
      {
        field: 'farm',
        operator: 'eq',
        value: sourceFarm,
      },
      {
        field: 'isGroup',
        operator: 'eq',
        value: IsGroupWarehouseEnum.NO,
      },
    ],
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    meta: metadata,
    queryOptions: {
      enabled: loadSourceWarehousesList,
    },
  });

  const {
    selectProps: targetWarehouseSelectProps,
    query: targetWarehouseQueryResult,
  } = useSelectBalance({
    resource: 'warehouse',
    optionValue: 'id',
    optionLabel: 'id',
    filters: [
      {
        field: 'farm',
        operator: 'eq',
        value: targetFarm ?? sourceFarm,
      },
      {
        field: 'isGroup',
        operator: 'eq',
        value: IsGroupWarehouseEnum.NO,
      },
    ],
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    meta: metadata,
    queryOptions: {
      enabled: loadTargetWarehousesList,
    },
  });

  const { selectProps: farmSelectProps, query: farmQueryResult } =
    useSelectBalance({
      resource: 'farm',
      optionValue: 'id',
      optionLabel: 'id',
      onSearch: (value) => [
        {
          field: 'name',
          operator: 'eq',
          value: value,
        },
      ],
      pagination: {
        pageSize: 50,
      },
      meta: metadata,
      queryOptions: {
        enabled: !!currentOrganizationId && !isOrganizationLoading,
      },
    });

  return {
    sourceFarm,
    targetFarm,
    transferBetweenFarms,
    farmSelectProps,
    sourceWarehouseSelectProps,
    isLoadingSourceWarehouse: sourceWarehouseQueryResult.isLoading,
    targetWarehouseSelectProps,
    isLoadingTargetWarehouse: targetWarehouseQueryResult.isLoading,
    isLoadingFarm: farmQueryResult.isLoading,
  };
};

export { useStockTransferDetails as default, useStockTransferDetails };
