import { Button } from 'antd';
import {
  HarvestIcon,
  OutboxIcon,
  PlantingIcon,
  SprayIcon,
  TillageIcon,
} from 'common/icons';
import styled from 'styled-components';
import {
  GreenColors,
  NeutralColors,
  OrangeColors,
  RedColors,
  YellowColors,
} from 'theme';

const OutboxIconContainer = styled(OutboxIcon)`
  fill: ${NeutralColors[60]};
  height: 20px;
`;

const SprayIconContainer = styled(SprayIcon)`
  fill: ${RedColors[60]};
  height: 20px;
`;

const LandPreparationIconContainer = styled(TillageIcon)`
  fill: ${YellowColors[60]};
  height: 20px;
`;

const PlantingIconIconContainer = styled(PlantingIcon)`
  fill: ${GreenColors[60]};
  height: 20px;
`;

const HarvestIconContainer = styled(HarvestIcon)`
  fill: ${OrangeColors[60]};
  height: 20px;
`;

const CreateButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: inline-block;
  }
`;

const ViewButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: inline-block;
  }
`;

export {
  CreateButton,
  HarvestIconContainer,
  LandPreparationIconContainer,
  OutboxIconContainer,
  PlantingIconIconContainer,
  SprayIconContainer,
  ViewButton,
};
