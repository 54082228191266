import { Button } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

const TotalBox = styled.p`
  display: flex;
  align-items: center;
  height: 30px;
  font-size: 16px;
  color: ${NeutralColors[60]};

  span {
    margin-left: 4px;
    font-weight: bold;
    color: ${NeutralColors[100]};
  }
`;

const ArchivedButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  border-radius: 16px;
  padding: 0;

  svg {
    width: 18px;
    height: 18px;

    fill: ${NeutralColors[60]} !important;
  }
`;

export { ArchivedButton, TotalBox };
