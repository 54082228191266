import { track } from '@amplitude/analytics-browser';
import { useList } from '@refinedev/core';
import { useState } from 'react';

import { CustomReportModel } from '../reports.model';

const useCustomReports = () => {
  const [selectedReport, setSelectedReport] = useState<string | undefined>();

  const { data, isLoading, isFetching } = useList<CustomReportModel>({
    resource: 'report/all',
    filters: [
      {
        field: 'isCustom',
        operator: 'eq',
        value: true,
      },
    ],
  });

  const handleOnClickReport = (reportName: string) => {
    setSelectedReport(reportName);
    track('Reports - Open Custom Report Drawer');
  };

  return {
    customReports: data?.data,
    isLoading: isLoading || isFetching,
    selectedReport,
    setSelectedReport,
    handleOnClickReport,
  };
};

export { useCustomReports };
