import { useDelete, useInvalidate, useOne } from '@refinedev/core';
import { usePermissionsBalance } from 'common/hooks/use-permissions-balance.hook';
import { ActionRbacEnum } from 'common/utils/auth-provider.util';
import { useCallback, useMemo } from 'react';

import { useOrganization } from '../../../organization';
import { MovementsModel } from '../movements.model';

interface MovementsDetailsProps {
  voucherNo: string;
  onClose: () => void;
}

const useMovementsDetailsDrawer = ({
  voucherNo,
  onClose,
}: MovementsDetailsProps) => {
  const { currentOrganizationId } = useOrganization();
  const { permissions } = usePermissionsBalance();
  const invalidate = useInvalidate();

  const { mutate: deleteMovement } = useDelete();

  const {
    data: movementsDetailsData,
    isLoading: isLoadingMovementsDetails,
    isFetching: isFetchingMovementsDetails,
  } = useOne<MovementsModel[]>({
    resource: 'movement/details',
    id: voucherNo,
    meta: {
      organizationId: currentOrganizationId,
    },
    queryOptions: {
      enabled: !!voucherNo && !!currentOrganizationId,
    },
  });

  const canDeleteMovement = useMemo(() => {
    return (
      permissions?.includes(ActionRbacEnum.STOCK_BALANCE_WRITE) &&
      permissions?.includes(ActionRbacEnum.FINANCIAL_BALANCE_WRITE) &&
      !movementsDetailsData?.data.find(
        (movement) => movement.type === 'AG_OPERATION',
      )
    );
  }, [permissions, movementsDetailsData]);

  const handleDeleteMovement = useCallback(
    (voucherNo: string) => {
      const voucherType = movementsDetailsData?.data.find(
        (movement) => movement.voucherNo === voucherNo,
      )?.voucherType;
      const resource =
        voucherType === 'Purchase Receipt' ? 'purchase-receipt' : 'stock-entry';

      deleteMovement(
        {
          resource,
          id: voucherNo,
          meta: {
            organizationId: currentOrganizationId,
          },
        },
        {
          onSuccess: () => {
            onClose();
            invalidate({
              resource: 'movement',
              invalidates: ['resourceAll'],
              invalidationOptions: {
                cancelRefetch: true,
              },
            });
          },
        },
      );
    },
    [deleteMovement, currentOrganizationId, movementsDetailsData],
  );

  return {
    movementsDetailsData,
    canDeleteMovement,
    handleDeleteMovement,
    isLoadingMovementsDetails:
      isLoadingMovementsDetails || isFetchingMovementsDetails,
  };
};

export { useMovementsDetailsDrawer as Default, useMovementsDetailsDrawer };
