import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useSelectBalance } from 'common/hooks/use-select-balance.hook';
import { FarmModel } from 'common/models';
import { useCallback, useEffect, useMemo } from 'react';
import { SeasonModel } from 'src/financial/financial.model';
import { useFarm, useOrganization } from 'src/organization';

const useMaterialConsumptionFormDetail = (drawerOpen: boolean) => {
  const { currentOrganizationId } = useOrganization();
  const { getCurrencyByFarm } = useFarm();
  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );
  const formInstance = useFormInstance();
  const farm = useWatch('farm');
  const costCenter = useWatch('costCenter');
  const isCropZone = useWatch('isCropZone');
  const seasonId = useWatch('seasonId');

  const enableRequest = useMemo(
    () => !!metadata.organizationId && drawerOpen,
    [metadata, drawerOpen],
  );

  const handleOnChangeFarm = useCallback(() => {
    formInstance.setFieldsValue({
      seasonId: undefined,
      project: undefined,
    });
  }, [formInstance]);

  const { selectProps: seasonSelectProps, query: seasonQueryResult } =
    useSelectBalance<SeasonModel>({
      resource: 'season',
      optionValue: 'seasonId',
      optionLabel: 'seasonName',
      filters: [
        {
          field: 'farm',
          operator: 'eq',
          value: farm,
        },
        {
          field: 'deleted',
          operator: 'eq',
          value: 'false',
        },
      ],
      onSearch: (value) => [
        {
          field: 'name',
          operator: 'eq',
          value: value,
        },
      ],
      meta: metadata,
      queryOptions: {
        enabled: !!farm,
      },
    });

  useEffect(() => {
    if (seasonId) {
      const selectedSeason = seasonQueryResult.data?.data.find(
        (season) => season.seasonId === seasonId,
      );
      if (selectedSeason) {
        formInstance.setFieldValue('costCenter', selectedSeason.costCenter);
      }
    }
  }, [formInstance, seasonId, seasonQueryResult.data?.data]);

  const { selectProps: projectSelectProps, query: projectQueryResult } =
    useSelectBalance({
      resource: 'project',
      optionValue: 'id',
      optionLabel: 'projectName',
      filters: [
        {
          field: 'farm',
          operator: 'eq',
          value: farm,
        },
        {
          field: 'seasonId',
          operator: 'eq',
          value: seasonId,
        },
      ],
      onSearch: (value) => [
        {
          field: 'name',
          operator: 'eq',
          value: value,
        },
      ],
      meta: metadata,
      queryOptions: {
        enabled: enableRequest && !!farm && !!costCenter && !!seasonId,
      },
    });

  const { selectProps: farmSelectProps } = useSelectBalance<FarmModel>({
    resource: 'farm',
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value,
      },
    ],
    pagination: {
      pageSize: 50,
    },
    meta: metadata,
    queryOptions: {
      enabled: enableRequest,
    },
  });

  useEffect(() => {
    if (farm) {
      formInstance?.setFieldValue('currency', getCurrencyByFarm(farm));
    }
  }, [farm, formInstance]);

  return {
    handleOnChangeFarm,
    isCropZone,
    farmSelectProps,
    seasonSelectProps,
    projectSelectProps,
    projectQueryResult,
    isLoadingSeason: seasonQueryResult?.isLoading,
  };
};

export {
  useMaterialConsumptionFormDetail as default,
  useMaterialConsumptionFormDetail,
};
