export enum FileTypeEnum {
  CSV = 'CSV',
  XLSX = 'Excel',
}

export enum DocTypeEnum {
  ITEM = 'Item',
  ITEM_GROUP = 'Item Group',
  SUPPLIER = 'Supplier',
  PURCHASE_ORDER = 'Purchase Order',
  PURCHASE_INVOICE = 'Purchase Invoice',
}

export enum GroupDocTypeEnum {
  MANAGEMENT = 'Management',
  RESOURCES = 'Resources',
}
