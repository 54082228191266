import { useOne } from '@refinedev/core';
import { FormListFieldData } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { useSelectBalance } from 'common/hooks/use-select-balance.hook';
import { useEffect, useMemo, useState } from 'react';
import { useOrganization } from 'src/organization';
import { ItemModel } from 'src/resources/item';
import { IsStockItemEnum } from 'src/stock/warehouse-list/warehouse-list.constant';

interface InvoiceFormItemProps {
  item: FormListFieldData;
  amountItems: number;
  index: number;
}

const useInvoiceFormItem = ({
  amountItems,
  index,
  item,
}: InvoiceFormItemProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const { currentOrganizationId, isLoading: isOrganizationLoading } =
    useOrganization();

  const selectedItem = useWatch(['items', item.name, 'itemCode']);
  const itemRate = useWatch(['items', item.name, 'rate']);
  const itemQuantity = useWatch(['items', item.name, 'qty']);
  const allData = useWatch([]);

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const loadItemsList = useMemo(
    () => !!currentOrganizationId && !isOrganizationLoading,
    [currentOrganizationId, isOrganizationLoading],
  );

  useEffect(() => {
    setIsOpen(amountItems <= index + 1);
  }, [amountItems, index]);

  const { selectProps: itemSelectProps, query: itemQueryResult } =
    useSelectBalance({
      resource: 'item',
      optionLabel: 'name',
      optionValue: 'id',
      filters: [
        {
          field: 'isStockItem',
          operator: 'eq',
          value: IsStockItemEnum.NO,
        },
      ],
      onSearch: (value) => [
        {
          field: 'name',
          operator: 'eq',
          value: value,
        },
      ],
      meta: metadata,
      queryOptions: {
        enabled: loadItemsList,
      },
    });

  const { data: itemData, isFetching: isFetchingItemData } = useOne<ItemModel>({
    resource: 'item',
    id: selectedItem,
    meta: {
      organizationId: currentOrganizationId,
    },
    queryOptions: {
      enabled: Boolean(selectedItem),
    },
  });

  return {
    currency: allData?.currency,
    itemSelectProps,
    isLoadingItemSelect: itemQueryResult.isLoading,
    isFetchingItemData,
    itemData,
    itemRate,
    itemQuantity,
    isOpen,
    setIsOpen,
  };
};

export { useInvoiceFormItem as default, useInvoiceFormItem };
