import { track } from '@amplitude/analytics-browser';
import { useOne } from '@refinedev/core';
import { downloadFile } from 'common/utils/download.util';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';

import { ExportFields, GroupDocType } from '../reports.constant';
import { DocTypeEnum, FileTypeEnum, GroupDocTypeEnum } from '../reports.enum';

interface DataExportHook {
  docType: DocTypeEnum | GroupDocTypeEnum | undefined;
  setDocType: (docType: DocTypeEnum | GroupDocTypeEnum | undefined) => void;
}

export const useDataExport = ({ docType, setDocType }: DataExportHook) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [isDownload, setIsDownload] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [groupDocType, setGroupDocType] = useState<
    GroupDocTypeEnum | undefined
  >();
  const [fileType, setFileType] = useState<FileTypeEnum>();
  const [nextDownload, setNextDownload] = useState<DocTypeEnum | undefined>();
  const userLanguage = i18n.language.toLocaleLowerCase();
  const { currentOrganizationId } = useOrganization();

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const showDrawer = () => {
    setOpen(true);
  };

  const hideDrawer = () => {
    setOpen(false);
    setFileType(undefined);
  };

  useEffect(() => {
    if (docType) {
      showDrawer();
    } else {
      hideDrawer();
    }
  }, [docType]);

  const {
    data: dataCsv,
    refetch,
    remove,
    isFetching,
  } = useOne({
    resource: 'export',
    id:
      isDownload &&
      !!Object.values(DocTypeEnum).includes(docType as DocTypeEnum)
        ? docType
        : undefined,
    meta: {
      params: {
        fileType,
        exportRecords: 'all',
        exportFields: Object.values(DocTypeEnum).includes(
          docType as DocTypeEnum,
        )
          ? ExportFields[docType as DocTypeEnum]
          : [],
        language: userLanguage,
        ...metadata,
      },
      responseType: 'arraybuffer',
    },
    queryOptions: {
      enabled:
        isDownload &&
        !!Object.values(DocTypeEnum).includes(docType as DocTypeEnum),
    },
  });

  useEffect(() => {
    if (dataCsv) {
      downloadFile(
        dataCsv.data as Blob,
        `${t('reports.doc_type.' + docType)}.${
          fileType === FileTypeEnum.CSV ? 'csv' : 'xlsx'
        }`,
      );
    }
    setIsDownload(false);
  }, [dataCsv]);

  useEffect(() => {
    if (!isFetching) {
      let currentNextDownload;
      if (groupDocType) {
        GroupDocType[groupDocType].forEach((key, index) => {
          if (key === nextDownload) {
            currentNextDownload = Object.values(GroupDocType[groupDocType])[
              index + 1
            ];
          }
        });
      }

      setDocType(nextDownload);
      setNextDownload(currentNextDownload);
      setIsDownload(!!nextDownload);
    }
  }, [isFetching, nextDownload, groupDocType]);

  const handleOnClickDownload = () => {
    if (
      (Object.values(GroupDocTypeEnum) as string[]).includes(docType as string)
    ) {
      handleDownloadAll();
    } else {
      if (dataCsv) {
        remove();
        refetch();
      }
      setIsDownload(true);
    }

    track('Reports - Download Report', {
      docType: docType,
      fileType,
    });
  };

  const handleDownloadAll = useCallback(() => {
    setGroupDocType(docType as GroupDocTypeEnum);
    setDocType(Object.values(GroupDocType[docType as GroupDocTypeEnum])[0]);
    setNextDownload(
      Object.values(GroupDocType[docType as GroupDocTypeEnum])[1],
    );
    setIsDownload(true);
  }, [docType]);

  return {
    open,
    fileType,
    isFetching,
    setFileType,
    handleOnClickDownload,
    handleDownloadAll,
  };
};
