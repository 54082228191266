import { useTable } from '@refinedev/core';
import { Space } from 'antd';
import { CardButton } from 'common/components';
import { ROUTES } from 'common/constants';
import { XlsxIcon } from 'common/icons';
import { useNavigateWithOrgAndFarm } from 'common/utils';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';

import { DataImportButtonResourceTestId } from '../reports.constant';
import { DocTypeEnum } from '../reports.enum';
import * as Styled from './button-resource.style';

interface ButtonResourceProps {
  docType: DocTypeEnum;
}

export const ButtonResource = ({ docType }: ButtonResourceProps) => {
  const { t, i18n } = useTranslation();
  const userLanguage = i18n.language.toLocaleLowerCase();
  const navigate = useNavigateWithOrgAndFarm();
  const { currentOrganizationId } = useOrganization();
  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const { tableQuery } = useTable({
    resource: `data-import/${docType}`,
    syncWithLocation: true,
    queryOptions: {
      enabled: !!currentOrganizationId && !!docType,
    },
    meta: metadata,
    filters: {
      initial: [
        {
          field: 'status',
          operator: 'eq',
          value: ['Success', 'Partial Success'],
        },
      ],
    },
  });

  const creation = tableQuery.data?.data[0]?.creation;

  const handleGoToList = () => {
    navigate(`${ROUTES.REPORTS_LIST_ROOT}/${encodeURIComponent(docType)}`);
  };

  if (!tableQuery?.data?.data?.length) {
    return null;
  }

  return (
    <CardButton
      title={
        <Space size={6}>
          <Styled.TotalCounter>
            {tableQuery.data?.data.length}
          </Styled.TotalCounter>
          {t(`reports.doc_type.${docType}`) ?? ''}
          &bull;
          {creation
            ? dayjs(tableQuery.data?.data[0].creation)
                .locale(userLanguage)
                .format('DD/MM/YYYY')
            : ''}
        </Space>
      }
      data-testid={
        DataImportButtonResourceTestId.REPORTS_BUTTON_RESOURCE_CONTAINER
      }
      icon={<XlsxIcon />}
      status={
        <Styled.StatusBox>
          <Styled.StatusIcon />
          {t('reports.imported_data.imported')}
        </Styled.StatusBox>
      }
      onClick={handleGoToList}
    />
  );
};
