import { Button, Tag as TagAntd } from 'antd';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface TagProps {
  textColor: string;
  isLink?: boolean;
}

const Tag = styled(TagAntd)<TagProps>`
  border-color: ${(props) => props.textColor};
  color: ${(props) => props.textColor};
  background-color: ${NeutralColors[0]};
  display: flex;
  align-items: center;
  width: max-content;
  cursor: ${(props) => (props.isLink ? 'pointer' : 'auto')};

  svg {
    margin-right: 8px;
  }
`;

const DeleteButton = styled(Button)`
  display: flex;
  align-items: center;

  svg {
    width: 16px;
  }
`;

export { DeleteButton, Tag };
