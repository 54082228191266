import { Grid } from 'antd';
import { DrawerWithButtons } from 'common/components';
import { CsvIcon, XlsxIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';

import { ReportsTestId } from '../reports.constant';
import { DocTypeEnum, FileTypeEnum, GroupDocTypeEnum } from '../reports.enum';
import { useDataExport } from './data-export.hook';
import * as Styled from './data-export.style';

interface DataExportProps {
  docType: DocTypeEnum | GroupDocTypeEnum | undefined;
  setDocType: (docType: DocTypeEnum | GroupDocTypeEnum | undefined) => void;
}

export const DataExport = ({ docType, setDocType }: DataExportProps) => {
  const breakpoint = Grid.useBreakpoint();
  const { open, isFetching, fileType, setFileType, handleOnClickDownload } =
    useDataExport({ docType, setDocType });
  const { t } = useTranslation();

  return (
    <DrawerWithButtons
      open={open}
      title={t('reports.export_button.modal.title')}
      onClose={() => setDocType(undefined)}
      onBack={() => setDocType(undefined)}
      textSave={t('reports.export_button.modal.ok_button') ?? ''}
      width={breakpoint.sm ? '400px' : '100%'}
      buttonSaveProps={{
        onClick: handleOnClickDownload,
      }}
      disabledSave={!fileType || !docType}
      data-testid={ReportsTestId.REPORTS_EXPORT_DRAWER}
      isLoading={isFetching}
    >
      <Styled.Title data-testid={ReportsTestId.REPORTS_EXPORT_DRAWER_DATA}>
        {t('reports.export_button.modal.select_title')}
      </Styled.Title>
      <Styled.ContainerFlexBetween>
        <Styled.ButtonSelectWidth
          icon={<CsvIcon />}
          title={t('reports.export_button.modal.csv_title')}
          description={t('reports.export_button.modal.csv_description')}
          onClick={() => setFileType(FileTypeEnum.CSV)}
          isSelected={fileType === FileTypeEnum.CSV}
        />
        <Styled.ButtonSelectWidth
          icon={<XlsxIcon />}
          title={t('reports.export_button.modal.xlsx_title')}
          description={t('reports.export_button.modal.xlsx_description')}
          onClick={() => setFileType(FileTypeEnum.XLSX)}
          isSelected={fileType === FileTypeEnum.XLSX}
        />
      </Styled.ContainerFlexBetween>
    </DrawerWithButtons>
  );
};
