import styled from 'styled-components';
import { GreenColors, NeutralColors } from 'theme';

const CardDataBase = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border: 1px solid ${NeutralColors[60]};
  border-radius: 8px;
  width: 40px;

  svg {
    fill: ${NeutralColors[80]};
    margin-right: 4px;
  }
`;

const StatusBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
`;

const StatusIcon = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: ${GreenColors[70]};
  margin-right: 4px;
`;

const TotalCounter = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: ${NeutralColors[100]};
  border-radius: 16px;
  border: 1px solid ${NeutralColors[60]};
  padding: 0 6px;
`;

export { CardDataBase, StatusBox, StatusIcon, TotalCounter };
