import { useList } from '@refinedev/core';
import { FarmIcon, PurchaseIcon, SupplierIcon, UpdateIcon } from 'common/icons';
import {
  ListFiltersModel,
  RefineTableFiltersEnum,
  useRefineTableFilters,
} from 'common/refine-table-filters';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFarm } from 'src/organization';

import { usePurchaseInvoiceDrawer } from '../invoice';
import { InvoiceFormModel } from '../invoice/invoice.model';
import { InvoicePaymentFormModel } from '../invoice/payment-form/invoice-payment-form.model';
import { usePurchaseDrawer } from '../purchase';
import {
  OtherCurrencyModel,
  PurchaseRequestModel,
} from '../purchase/purchase.model';
import { PurchasePaymentRequestModel } from '../purchase/purchase-payment/purchase-payment.model';
import {
  AccountsPayableEnum,
  AccountsPayableStatusEnum,
} from './accounts-payable.enum';
import { AccountsPayableModel } from './accounts-payable.model';

const useAccountsPayable = () => {
  const { t } = useTranslation();
  const { currentFarm, getCurrencyByFarm } = useFarm();

  const { purchaseInvoiceDrawer: paymentInvoiceDrawer } =
    usePurchaseInvoiceDrawer<InvoicePaymentFormModel>('edit', 'payment');
  const { purchaseInvoiceDrawer: viewInvoiceDrawer } =
    usePurchaseInvoiceDrawer<InvoiceFormModel>('edit');
  const { purchaseDrawer: paymentPurchaseDrawer } =
    usePurchaseDrawer<PurchasePaymentRequestModel>('edit');
  const { purchaseDrawer: viewDrawer } =
    usePurchaseDrawer<PurchaseRequestModel>('edit');

  const listFilters: ListFiltersModel[] = useMemo(
    () => [
      {
        label: t('common.business.farm'),
        field: 'farm',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'farm',
        show: true,
        icon: <FarmIcon />,
        initialValue: currentFarm?.name,
      },
      {
        label: t('common.business.supplier'),
        field: 'supplier',
        type: RefineTableFiltersEnum.SELECT,
        resource: 'supplier',
        filters: [
          {
            field: 'disabled',
            operator: 'eq',
            value: false,
          },
        ],
        show: true,
        icon: <SupplierIcon />,
        optionLabel: 'projectName',
      },
      {
        label: t('common.type'),
        field: 'type',
        type: RefineTableFiltersEnum.SELECT,
        show: true,
        icon: <PurchaseIcon />,
        options: [
          {
            label: t('purchase.title'),
            value: AccountsPayableEnum.PURCHASE_ORDER,
          },
          {
            label: t('invoice.title'),
            value: AccountsPayableEnum.PURCHASE_INVOICE,
          },
        ],
      },
      {
        label: t('material_consumption.table.status'),
        field: 'status',
        type: RefineTableFiltersEnum.SELECT,
        show: false,
        icon: <UpdateIcon />,
        options: Object.values(AccountsPayableStatusEnum).map((value) => ({
          label: t(`accounts_payable.status.${value}`),
          value,
        })),
      },
    ],
    [t],
  );

  const { data: otherCurrencyData } = useList<OtherCurrencyModel>({
    resource: 'other-currency',
    pagination: {
      current: 1,
      pageSize: 1000,
    },
  });

  const otherCurrencyById = useMemo(() => {
    return otherCurrencyData?.data.reduce(
      (acc: Record<string, OtherCurrencyModel>, curr: OtherCurrencyModel) => {
        acc[curr.id] = curr;
        return acc;
      },
      {} as Record<string, OtherCurrencyModel>,
    );
  }, [otherCurrencyData]);

  const { tableProps, searchFormProps, tableQuery } =
    useRefineTableFilters<AccountsPayableModel>({
      listFilters,
      resource: 'report/accounts-payable',
    });

  return {
    tableProps,
    searchFormProps,
    tableQuery,
    paymentInvoiceDrawer,
    viewInvoiceDrawer,
    paymentPurchaseDrawer,
    viewDrawer,
    otherCurrencyById,
    listFilters,
    getCurrencyByFarm,
  };
};

export { useAccountsPayable };
