import { Typography } from 'antd';
import { TestingIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';
import { BlueColors } from 'theme';

import * as Styled from './card-testing.style';

const CardTesting = () => {
  const { t } = useTranslation();

  return (
    <Styled.Container>
      <Styled.TestingTag icon={<TestingIcon />} color={BlueColors[60]}>
        {t('common.testing').toUpperCase()}
      </Styled.TestingTag>
      <Typography.Title level={5}>
        {t('reports.start_import.alert_title')}
      </Typography.Title>
      <Typography.Paragraph>
        {t('reports.start_import.alert_message')}
      </Typography.Paragraph>
    </Styled.Container>
  );
};

export { CardTesting };
