import { IResourceComponentsProps } from '@refinedev/core';
import { Button, Space, Table } from 'antd';
import { usePermissionsBalance } from 'common/hooks/use-permissions-balance.hook';
import { AddIcon, EyeOutlinedIcon } from 'common/icons';
import { RefineTableFilters } from 'common/refine-table-filters';
import { ActionRbacEnum } from 'common/utils/auth-provider.util';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';
import EntitlementEnum from 'src/organization/entitlements.enum';

import { OrdersAndPaymentsEnum } from '../orders-and-payments.enum';
import { OrdersAndPaymentsTitle } from '../orders-and-payments-title.component';
import { DateColumn } from './columns/date-column/date-column.component';
import TotalColumn from './columns/total-column/total-column.component';
import { ElectronicInvoicesModal } from './electronic-invoices-modal/electronic-invoices-modal.component';
import InvoiceReceiptTable from './invoice-receipt-table/invoice-receipt-table.component';
import { PurchaseModel } from './purchase.model';
import { PurchaseCreateContainer } from './purchase-create/purchase-create.container';
import { usePurchaseList } from './purchase-list.hook';
import * as Styled from './purchase-list.style';
import PurchasePayment from './purchase-payment/purchase-payment.container';
import PurchaseReceive from './purchase-receive/purchase-receive.container';
import { PurchaseStatus } from './purchase-status/purchase-status.component';
import { StatusEnum } from './status.enum';

const PurchaseListContainer: FC<IResourceComponentsProps> = () => {
  const { t } = useTranslation();
  const { permissions } = usePermissionsBalance();
  const { invoiceReceiptTableFms849 } = useFlags();
  const { entitlements } = useOrganization();
  const {
    tableProps,
    searchFormProps,
    listFilters,
    onRowClick,
    invoiceDrawer,
    receiveDrawer,
    createDrawer,
    viewDrawer,
    otherCurrencyById,
    handleOnClickCreatePurchase,
    handleOnClickInvoicePurchase,
    handleOnClickReceivePurchase,
    handleRenderExpandIcon,
  } = usePurchaseList();

  return (
    <>
      <PurchasePayment drawerForm={invoiceDrawer} />
      <PurchaseReceive
        drawerProps={receiveDrawer.drawerProps}
        formProps={receiveDrawer.formProps}
        onClose={receiveDrawer.close}
        receiveQueryResult={receiveDrawer?.query}
        formLoading={receiveDrawer.formLoading}
        formInstance={receiveDrawer.form}
        onCreate={receiveDrawer.saveButtonProps}
      />
      <PurchaseCreateContainer drawerForm={createDrawer} />
      <PurchaseCreateContainer drawerForm={viewDrawer} isReadonly />
      <OrdersAndPaymentsTitle
        buttons={
          permissions?.includes(ActionRbacEnum.STOCK_BALANCE_READ) ? (
            <Styled.CreateButton
              onClick={handleOnClickCreatePurchase}
              icon={<AddIcon />}
              type="primary"
            >
              {t('buttons.create')}
            </Styled.CreateButton>
          ) : undefined
        }
        selectedTab={OrdersAndPaymentsEnum.PURCHASE}
      />
      <RefineTableFilters
        searchFormProps={searchFormProps}
        initialListFilters={listFilters}
      />
      <Styled.Table
        {...tableProps}
        onRow={(record) => {
          return {
            onClick: (event) => onRowClick(event, record),
          };
        }}
        expandable={{
          rowExpandable: () => invoiceReceiptTableFms849,
          expandedRowRender: ({ id }) => (
            <InvoiceReceiptTable purchaseId={id} />
          ),
          expandIcon: handleRenderExpandIcon,
        }}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
        }}
      >
        <Table.Column
          dataIndex="transaction_date"
          title={t('purchase.table.date')}
          render={(_, { transactionDate, purchaseNumber }: PurchaseModel) => (
            <DateColumn
              date={transactionDate}
              purchaseNumber={purchaseNumber}
            />
          )}
          sorter
        />
        <Table.Column dataIndex="farmName" title={t('purchase.table.farm')} />
        <Table.Column
          dataIndex="supplier"
          title={t('purchase.table.supplier')}
        />

        <Table.Column
          dataIndex="status"
          title={t('purchase.table.status')}
          render={(status) => <PurchaseStatus status={status} />}
          sorter
        />
        {entitlements.includes(EntitlementEnum.INVOICES) && (
          <Table.Column
            dataIndex="percentBilled"
            title={t('purchase.table.billed')}
            render={(percent) => (
              <Styled.Progress percent={Math.round(percent)} size="small" />
            )}
          />
        )}
        <Table.Column
          dataIndex="percentReceived"
          title={t('purchase.table.received')}
          render={(percent) => (
            <Styled.Progress percent={Math.round(percent)} size="small" />
          )}
        />
        {permissions?.includes(ActionRbacEnum.FINANCIAL_BALANCE_READ) && (
          <Table.Column
            dataIndex="grand_total"
            title={t('purchase.table.total')}
            render={(
              _,
              {
                currency,
                grandTotalWithDiff,
                isOtherCurrency,
                valueOtherCurrency,
                typeOtherCurrency,
              }: PurchaseModel,
            ) => {
              return (
                <TotalColumn
                  total={grandTotalWithDiff}
                  currency={currency}
                  otherCurrencyTotal={valueOtherCurrency}
                  isOtherCurrency={isOtherCurrency}
                  otherCurrency={otherCurrencyById?.[typeOtherCurrency]}
                />
              );
            }}
            sorter
          />
        )}
        <Table.Column
          title={t('purchase.table.actions')}
          render={({
            id,
            status,
            supplier,
            transactionDate,
          }: PurchaseModel) => (
            <Space>
              {permissions?.includes(ActionRbacEnum.FINANCIAL_BALANCE_READ) &&
                entitlements.includes(EntitlementEnum.INVOICES) && (
                  <Button
                    disabled={
                      !(
                        status === StatusEnum.ToBill ||
                        status === StatusEnum.ToReceiveAndBill
                      )
                    }
                    onClick={() => handleOnClickInvoicePurchase(id)}
                  >
                    {t('purchase.table.pay')}
                  </Button>
                )}
              {permissions?.includes(ActionRbacEnum.STOCK_BALANCE_READ) && (
                <Button
                  onClick={() => handleOnClickReceivePurchase(id)}
                  disabled={
                    !(
                      status === StatusEnum.ToReceive ||
                      status === StatusEnum.ToReceiveAndBill
                    )
                  }
                >
                  {t('purchase.table.receive')}
                </Button>
              )}
              {entitlements.includes(
                EntitlementEnum.ELETRONIC_INVOICES_XML_IMPORT,
              ) && (
                <ElectronicInvoicesModal
                  supplier={supplier}
                  date={transactionDate}
                  purchaseOrderId={id}
                />
              )}
              {permissions?.includes(ActionRbacEnum.STOCK_BALANCE_READ) && (
                <Styled.RoundButton
                  onClick={() => viewDrawer.show(id)}
                  icon={<EyeOutlinedIcon />}
                />
              )}
            </Space>
          )}
        />
      </Styled.Table>
    </>
  );
};

export { PurchaseListContainer as default, PurchaseListContainer };
