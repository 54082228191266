import {
  add,
  Identify,
  identify,
  init,
  track as ampTrack,
} from '@amplitude/analytics-browser';
import { EnrichmentPlugin, Event } from '@amplitude/analytics-types';
import { Grid } from 'antd';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/auth';
import { useFarm, useOrganization } from 'src/organization';

import { TRACKING_KEY, TRACKING_OPTIONS } from './tracking.constant';

type TrackingHookProps = {
  track: (
    action: string,
    additionalProperties?: Record<string, string | boolean>,
  ) => void;
};

const useTracking = (): TrackingHookProps => {
  const track = useCallback(
    (
      action: string,
      additionalProperties: Record<string, string | boolean> = {},
    ) => {
      const actionProperties: Record<string, string | boolean | null> = {
        ...additionalProperties,
      };

      ampTrack(action, actionProperties);
    },
    [],
  );

  return {
    track,
  };
};

const useTrackingEnrichProperties = () => {
  const { currentOrganization, currentWorkspaceId, plan } = useOrganization();
  const { user } = useAuth();
  const { i18n } = useTranslation();
  const { currentFarm } = useFarm();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.md;

  const defaultProperties = useMemo(() => {
    return {
      cropwiseUserId: user?.id ?? '',
      organizationId: currentOrganization?.id ?? '',
      organizationName: currentOrganization?.name ?? '',
      workspaceId: currentWorkspaceId ?? '',
      workspaceName: currentOrganization?.workspace_name ?? '',
      farmId: currentFarm?.externalId ?? '',
      farmName: currentFarm?.name ?? '',
      jobTitle: user?.job_title ?? '',
      planId: plan?.id ?? '',
      planName: plan?.name ?? '',
      userLanguage: user?.locale ?? '',
      mobileScreen: isMobile,
      interfaceLanguage: i18n.language,
    };
  }, [
    currentFarm?.externalId,
    currentFarm?.name,
    currentOrganization?.id,
    currentOrganization?.name,
    currentOrganization?.workspace_name,
    currentWorkspaceId,
    user?.job_title,
    user?.id,
    user?.locale,
    plan?.id,
    plan?.name,
    i18n.language,
    isMobile,
  ]);

  class EnrichPropertiesPlugin implements EnrichmentPlugin {
    name = 'enrichPropertiesPlugin';

    async setup() {
      if (defaultProperties) {
        const identifyEvent = new Identify();
        identifyEvent.set('user_properties', defaultProperties);
        identify(identifyEvent);
      }
      return undefined;
    }

    async execute(event: Event) {
      return event;
    }
  }

  add(new EnrichPropertiesPlugin());
  init(TRACKING_KEY, TRACKING_OPTIONS);
};

export { useTracking as default, useTracking, useTrackingEnrichProperties };
