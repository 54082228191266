import {
  Button,
  Col,
  Form,
  FormListFieldData,
  Row,
  Skeleton,
  Typography,
} from 'antd';
import { FarmStorageIcon, LocalShippingIcon } from 'common/icons';
import { getCurrencySymbol } from 'common/utils';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { PurchaseReceiveItemTestId } from '../purchase-receive.constants';
import usePurchaseReceiveItem from './purchase-receive-item.hook';
import * as Styled from './purchase-receive-item.style';

interface PurchaseReceiveItemProps {
  itemFieldData: FormListFieldData;
  showUnitValueAlert: boolean;
}

export const PurchaseReceiveItem = ({
  itemFieldData,
  showUnitValueAlert,
}: PurchaseReceiveItemProps) => {
  const { t } = useTranslation();

  const {
    itemName,
    stockUom,
    warehouseName,
    warehouseIsLoading,
    currency,
    outstadingQty,
    totalQty,
    lockedQty,
    handleHideItem,
    isHidden,
  } = usePurchaseReceiveItem(itemFieldData);

  const handlePrefixInputUnitValue = useCallback(() => {
    return getCurrencySymbol(currency);
  }, [currency, getCurrencySymbol]);

  return (
    <Styled.Container
      data-testid={PurchaseReceiveItemTestId.PURCHASE_RECEIVE_ITEM_CONTAINER}
      hidden={isHidden}
    >
      <Styled.TitleWrapper>
        <Styled.Title level={4}>{itemName}</Styled.Title>
        <Button
          shape="circle"
          size="large"
          icon={<Styled.DeleteIconCustom />}
          data-testid={PurchaseReceiveItemTestId.PURCHASE_RECEIVE_ITEM_TRASH}
          onClick={handleHideItem}
        />
      </Styled.TitleWrapper>
      <Row justify="space-between" gutter={[4, 4]}>
        <Col span={12}>
          <Typography.Text type="secondary">
            {t('purchase_receive.item.warehouse')}
          </Typography.Text>
          <Styled.InfoWrapper>
            <Styled.IconWrapper>
              <FarmStorageIcon />
            </Styled.IconWrapper>
            {warehouseIsLoading ? (
              <Skeleton.Button active />
            ) : (
              <Styled.WarehouseName>{warehouseName}</Styled.WarehouseName>
            )}
          </Styled.InfoWrapper>
        </Col>
        <Col span={12}>
          <Typography.Text type="secondary">
            {t('purchase_receive.item.pending_qty')}
          </Typography.Text>
          <Styled.InfoWrapper>
            <Styled.IconWrapper>
              <LocalShippingIcon />
            </Styled.IconWrapper>
            <Typography.Text>
              {t('purchase_receive.item.pending_qty_amount', {
                pending: outstadingQty,
                total: totalQty,
                uom: stockUom,
              })}
            </Typography.Text>
          </Styled.InfoWrapper>
        </Col>
        <Col span={12}>
          <Form.Item
            name={[itemFieldData.name, 'rate']}
            label={t('purchase_receive.item.unit_value')}
            rules={[
              {
                required: true,
              },
              {
                type: 'number',
                min: 0.000001,
                message: t('purchase_receive.item.unit_value_error') ?? '',
              },
              {
                validator: (_, value) => {
                  if (value > 0 && !showUnitValueAlert) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error());
                },
              },
            ]}
          >
            <Styled.InputUnitValue
              prefix={handlePrefixInputUnitValue()}
              data-testid={
                PurchaseReceiveItemTestId.PURCHASE_RECEIVE_ITEM_UNIT_VALUE
              }
              disabled={lockedQty === totalQty}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={[itemFieldData.name, 'receivedQty']}
            label={
              <Typography style={{ textWrap: 'nowrap' }}>
                {t('purchase_receive.item.amount_received')}
              </Typography>
            }
            rules={[
              {
                required: true,
              },
              {
                type: 'number',
                min: 0,
                message: t('purchase_receive.item.amount_received_error') ?? '',
              },
            ]}
          >
            <Styled.InputQty
              suffix={stockUom}
              min={1}
              data-testid={
                PurchaseReceiveItemTestId.PURCHASE_RECEIVE_ITEM_INPUT
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </Styled.Container>
  );
};
