import { useList, useOne } from '@refinedev/core';
import { notification } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';
import { PLATFORM_DATA_CONFIG } from 'common/constants';
import dayjs from 'dayjs';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

import FarmsProvider from './farm/farm.provider';
import { Licensing, LicensingPlan, Organization } from './organization.model';

interface OrganizationContextProps {
  organizations: Organization[];
  plan: LicensingPlan | null;
  entitlements: string[];
  currentOrganization: Organization | null;
  currentOrganizationId: string | null;
  currentWorkspaceId: string | null;
  setCurrentOrganizationId: (organizationId: string | null) => void;
  isLoading: boolean;
  isFetched: boolean;
  error: Error | null | unknown;
  apiNotification?: NotificationInstance;
}

const OrganizationContext = createContext<OrganizationContextProps>({
  organizations: [],
  plan: null,
  entitlements: [],
  currentOrganization: null,
  currentOrganizationId: null,
  currentWorkspaceId: null,
  setCurrentOrganizationId: () => null,
  isLoading: false,
  isFetched: false,
  error: null,
});

const OrganizationProvider = ({ children }: PropsWithChildren) => {
  const [apiNotification, contextHolder] = notification.useNotification();
  const [currentOrganizationId, setCurrentOrganizationId] = useState<
    string | null
  >(null);

  const { data, error, isLoading, isFetching, isFetched } =
    useList<Organization>({
      meta: {
        isOptionalOrganization: true,
      },
      resource: 'organization-user',
    });

  const { data: licensingData } = useOne<Licensing>({
    dataProviderName: 'platformV2',
    resource: 'licensing',
    id: 'status',
    meta: {
      params: {
        org: currentOrganizationId,
        app: 'all',
      },
    },
    queryOptions: {
      enabled: !!currentOrganizationId,
    },
  });

  const plan = useMemo(
    () =>
      licensingData?.data?.apps
        .filter((app) => app.id === PLATFORM_DATA_CONFIG.APP_ID)
        .find(
          (app) =>
            dayjs(app.contract.start).isBefore(dayjs()) &&
            dayjs(app.contract.end).isAfter(dayjs()),
        )?.plan ?? null,
    [licensingData],
  );

  const entitlements = useMemo(
    () => plan?.entitlements.map((entitlement) => entitlement.key) ?? [],
    [plan],
  );

  const organizations = useMemo(() => data?.data ?? [], [data]);

  const currentOrganization = useMemo(
    () => organizations.find((org) => org.id === currentOrganizationId) ?? null,
    [organizations, currentOrganizationId],
  );

  const currentWorkspaceId = useMemo(() => {
    const workspaceId = currentOrganization?.crn.match(
      /workspace:([a-f0-9-]+)/,
    );
    return workspaceId ? workspaceId[1] : null;
  }, [currentOrganization]);

  const value = useMemo(
    () => ({
      organizations,
      plan,
      entitlements,
      currentOrganization,
      currentOrganizationId,
      currentWorkspaceId,
      setCurrentOrganizationId,
      isLoading: isLoading ?? isFetching,
      isFetched,
      error,
      apiNotification,
    }),
    [
      organizations,
      entitlements,
      currentOrganization,
      currentOrganizationId,
      currentWorkspaceId,
      isLoading,
      isFetching,
      error,
      apiNotification,
    ],
  );

  return (
    <OrganizationContext.Provider value={value}>
      {contextHolder}
      <FarmsProvider>{children}</FarmsProvider>
    </OrganizationContext.Provider>
  );
};

const useOrganization = () => {
  const context = useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error(
      'useOrganization must be used within an OrganizationProvider',
    );
  }
  return context;
};

export { OrganizationProvider, useOrganization };
