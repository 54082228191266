import { useList } from '@refinedev/core';
import { Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { SelectWithButton } from 'common/components';
import { useSelectBalance } from 'common/hooks/use-select-balance.hook';
import { CheckIcon, EditIcon, HelpIcon } from 'common/icons';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PurchaseOrderSupplierTestId } from 'src/orders-and-payments/electronic-invoice/electronic-invoice-test-id.constants';
import { useOrganization } from 'src/organization';
import { SupplierCreateContainer } from 'src/resources/supplier/supplier-create/supplier-create.container';
import { useSupplierCreateDrawer } from 'src/resources/supplier/use-supplier-create-drawer.hook';

import * as Styled from './purchase-order-supplier.style';

interface PurchaseOrderSupplierProps {
  eletronicInvoiceSupplier?: string;
}

const PurchaseOrderSupplier = ({
  eletronicInvoiceSupplier,
}: PurchaseOrderSupplierProps) => {
  const supplier = useWatch(['supplier']);
  const sourceCnpjCpf = useWatch(['sourceCnpjCpf']);
  const formInstance = useFormInstance();
  const [openEdit, setOpenEdit] = useState(false);
  const { t } = useTranslation();
  const { currentOrganizationId } = useOrganization();
  const { createSupplierDrawer, handleOpenSupplierDrawer } =
    useSupplierCreateDrawer();
  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const { data: supplierWithCnpj } = useList({
    resource: 'supplier',
    filters: [
      {
        field: 'cnpjCpf',
        operator: 'eq',
        value: sourceCnpjCpf,
      },
      {
        field: 'disabled',
        operator: 'eq',
        value: false,
      },
    ],
    meta: metadata,
    queryOptions: {
      enabled: !!metadata.organizationId && !!sourceCnpjCpf,
    },
  });

  useEffect(() => {
    if (supplierWithCnpj?.data.length) {
      formInstance.setFieldsValue({
        supplier: supplierWithCnpj.data[0].name,
      });
    }
  }, [supplierWithCnpj]);

  const { selectProps: supplierSelect } = useSelectBalance({
    optionLabel: 'name',
    optionValue: 'id',
    resource: 'supplier',
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    filters: [
      {
        field: 'hasCnpjCpf',
        operator: 'eq',
        value: false,
      },
      {
        field: 'disabled',
        operator: 'eq',
        value: false,
      },
    ],
    meta: metadata,
    queryOptions: {
      enabled: !!metadata.organizationId,
    },
  });

  const handleChangeOpenEdit = () => {
    if (supplierWithCnpj?.data.length) return;
    setOpenEdit(!openEdit);
  };

  return (
    <Styled.Box isSuccess={!!supplier}>
      <SupplierCreateContainer
        supplierDrawerForm={createSupplierDrawer}
        breadcrumbList={[t('electronic_invoice.link.title')]}
      />
      <Styled.FormItem
        name={['supplier']}
        rules={[
          {
            required: true,
          },
        ]}
      >
        {openEdit ? (
          <SelectWithButton
            {...supplierSelect}
            style={{ width: 360 }}
            defaultVisible
            data-testid={
              PurchaseOrderSupplierTestId.PURCHASE_ORDER_SUPPLIER_SELECT
            }
            buttonProps={{
              onClick: handleOpenSupplierDrawer,
              children: t('common.create_new'),
            }}
          />
        ) : (
          <Styled.BoxWarehouse
            isSuccess={!!supplier}
            onClick={handleChangeOpenEdit}
          >
            {supplier ? (
              <>
                <CheckIcon />
                {supplier}
              </>
            ) : (
              <>
                <HelpIcon />
                <Typography.Text>
                  {t('electronic_invoice.purchase_order.supplier')}
                  <Typography.Text type="secondary">
                    {` ${eletronicInvoiceSupplier}`}
                  </Typography.Text>
                </Typography.Text>
              </>
            )}
          </Styled.BoxWarehouse>
        )}
      </Styled.FormItem>
      <Styled.EditButton
        data-testid={PurchaseOrderSupplierTestId.PURCHASE_ORDER_SUPPLIER_BUTTON}
        onClick={handleChangeOpenEdit}
        disabled={!!supplierWithCnpj?.data.length}
      >
        {openEdit ? <CheckIcon /> : <EditIcon />}
      </Styled.EditButton>
    </Styled.Box>
  );
};

export { PurchaseOrderSupplier };
