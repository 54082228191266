import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useSelectBalance } from 'common/hooks/use-select-balance.hook';
import { useCallback, useEffect, useMemo } from 'react';
import { useOtherCurrenciesHelp } from 'src/orders-and-payments/purchase/other-currencies-help/other-currencies-help.hook';
import { OtherCurrencyModel } from 'src/orders-and-payments/purchase/purchase.model';
import { useFarm, useOrganization } from 'src/organization';

import { usePurchaseCreateStore } from '../../purchase-create.store';

const useOtherCurrencies = () => {
  const formInstance = useFormInstance();
  const { currentOrganizationId } = useOrganization();
  const { getCurrencyByFarm } = useFarm();
  const { setCustomCurrency, customCurrency } = usePurchaseCreateStore();

  const isOtherCurrency = useWatch('isOtherCurrency');
  const typeOtherCurrency = useWatch('typeOtherCurrency');
  const farmName = useWatch('farmName');

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const {
    selectProps: otherCurrencySelectProps,
    query: otherCurrencyQueryResult,
  } = useSelectBalance<OtherCurrencyModel>({
    resource: 'other-currency',
    optionLabel: 'name',
    optionValue: 'id',
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    meta: metadata,
    queryOptions: {
      enabled: !!metadata.organizationId,
    },
  });

  useEffect(() => {
    const selectedCurrency = otherCurrencyQueryResult.data?.data.find(
      (currency) => currency.id === typeOtherCurrency,
    );

    selectedCurrency && setCustomCurrency(selectedCurrency);
  }, [otherCurrencyQueryResult.data, typeOtherCurrency, setCustomCurrency]);

  const renderCustomLabel = useCallback(() => {
    if (customCurrency?.isMonetary) {
      return `= ${customCurrency.symbol} 1,00`;
    }
    return `= 1.00 ${customCurrency?.symbol}`;
  }, [otherCurrencyQueryResult.data, formInstance, customCurrency]);

  const farmCurrency = useMemo(() => {
    return getCurrencyByFarm(farmName);
  }, [farmName, getCurrencyByFarm]);

  const { handleOpenOtherCurrenciesHelp, modalProps } =
    useOtherCurrenciesHelp();

  return {
    otherCurrencySelectProps,
    isOtherCurrency,
    renderCustomLabel,
    getCurrencyByFarm,
    farmCurrency,
    handleOpenOtherCurrenciesHelp,
    otherCurrenciesModalProps: modalProps,
  };
};

export { useOtherCurrencies };
