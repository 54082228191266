import { Divider, Space } from 'antd';
import { FC, ReactNode } from 'react';

import * as Styled from './group-title.style';

interface GroupTitleComponentProps {
  title: string;
  description?: ReactNode;
  icon?: ReactNode;
}

const GroupTitleComponent: FC<GroupTitleComponentProps> = ({
  title,
  description,
  icon,
}) => {
  return (
    <Styled.TitleWrapper direction="vertical" size={8}>
      <Space>
        <Styled.IconWrapper>{icon}</Styled.IconWrapper>

        <Space direction="vertical" size={4}>
          <Styled.TypographyTitle level={3}>{title}</Styled.TypographyTitle>
          {description}
        </Space>
      </Space>

      <Divider variant="solid" />
    </Styled.TitleWrapper>
  );
};

export { GroupTitleComponent as default, GroupTitleComponent };
