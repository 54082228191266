import { Drawer } from 'antd';
import styled from 'styled-components';
import { GreenColors, NeutralColors } from 'theme';

const DrawerLayout = styled(Drawer)`
  color: ${NeutralColors[0]};
  background-color: ${NeutralColors[0]} !important;

  .fms-drawer-body {
    padding: 0;
    margin-bottom: 62px;
  }

  .fms-menu {
    background-color: transparent;
    border: none !important;
  }

  .fms-menu-item {
    border: none;
    border-radius: 0;
    background-color: ${NeutralColors[0]};
    margin: 0 !important;
    width: 100%;
    height: 66px;
    color: ${NeutralColors[80]};

    svg {
      fill: currentColor;
    }
  }

  .fms-menu-submenu {
    &-title {
      border: none;
      border-radius: 0;
      background-color: ${NeutralColors[0]};
      margin: 0 !important;
      width: 100%;
      height: 66px !important;
      color: ${NeutralColors[80]};

      svg {
        fill: currentColor;
      }
    }

    &-selected {
      .fms-menu-submenu-title {
        background-color: ${NeutralColors[10]};
        color: ${GreenColors[60]};

        ::before {
          content: '';
          background-color: ${GreenColors[60]};
          border-radius: 0 4px 4px 0;
          width: 4px;
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          height: calc(100% - 8px);
        }
      }
    }
  }

  .fms-menu-item-selected {
    background-color: ${NeutralColors[10]};
    color: ${GreenColors[60]};

    ::before {
      content: '';
      background-color: ${GreenColors[60]};
      border-radius: 0 4px 4px 0;
      width: 4px;
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: calc(100% - 8px);
    }
  }
`;

const ItemDescription = styled.div`
  display: grid;
  align-items: start;
  grid-template-rows: 1fr 1fr;
  padding: 8px 0;
  border-bottom: 1px solid ${NeutralColors[20]};
`;

export { DrawerLayout, ItemDescription };
