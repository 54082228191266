import { BaseKey, HttpError } from '@refinedev/core';
import { FarmIcon, SupplierIcon, UpdateIcon } from 'common/icons';
import {
  ListFiltersModel,
  RefineTableFiltersEnum,
  useRefineTableFilters,
} from 'common/refine-table-filters';
import { useTracking } from 'common/utils';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFarm } from 'src/organization';

import { InvoiceFormModel, InvoiceModel } from './invoice.model';
import { usePurchaseInvoiceDrawer } from './payment-form/invoice-payment-form.hook';
import { InvoicePaymentFormModel } from './payment-form/invoice-payment-form.model';
import {
  StatusEnum,
  StatusTranslationEnum,
} from './status/invoice-status.constant';

interface FilterFormModel {
  farms: string[];
  name: string;
  category: string;
}

const useInvoiceList = () => {
  const { track } = useTracking();
  const { currentFarm } = useFarm();
  const { t } = useTranslation();
  const { purchaseInvoiceDrawer: paymentDrawer } =
    usePurchaseInvoiceDrawer<InvoicePaymentFormModel>('edit', 'payment');
  const { purchaseInvoiceDrawer: createDrawer } =
    usePurchaseInvoiceDrawer<InvoiceFormModel>('create');
  const { purchaseInvoiceDrawer: editDrawer } =
    usePurchaseInvoiceDrawer<InvoiceFormModel>('edit');

  const optionsStatus = useMemo(
    () => [
      {
        label: t(`invoice.status.${StatusTranslationEnum[StatusEnum.Overdue]}`),
        value: StatusEnum.Overdue,
      },
      {
        label: t(`invoice.status.${StatusTranslationEnum[StatusEnum.Paid]}`),
        value: StatusEnum.Paid,
      },
      {
        label: t(
          `invoice.status.${StatusTranslationEnum[StatusEnum.PartlyPaid]}`,
        ),
        value: StatusEnum.PartlyPaid,
      },
      {
        label: t(`invoice.status.${StatusTranslationEnum[StatusEnum.Unpaid]}`),
        value: StatusEnum.Unpaid,
      },
      {
        label: t(
          `invoice.status.${StatusTranslationEnum[StatusEnum.Cancelled]}`,
        ),
        value: StatusEnum.Cancelled,
      },
    ],
    [t],
  );

  const listFilters: ListFiltersModel[] = useMemo(
    () => [
      {
        label: t('invoice.farm'),
        field: 'farms',
        type: RefineTableFiltersEnum.MULTIPLE_SELECT,
        resource: 'farm',
        show: true,
        icon: <FarmIcon />,
      },
      {
        label: t('invoice.supplier'),
        field: 'suppliersId',
        type: RefineTableFiltersEnum.MULTIPLE_SELECT,
        resource: 'supplier',
        filters: [
          {
            field: 'disabled',
            operator: 'eq',
            value: false,
          },
        ],
        icon: <SupplierIcon />,
        show: false,
      },
      {
        label: t('invoice.status.title'),
        field: 'status',
        type: RefineTableFiltersEnum.MULTIPLE_SELECT,
        show: false,
        icon: <UpdateIcon />,
        options: optionsStatus,
        initialValue: [
          StatusEnum.Overdue,
          StatusEnum.Paid,
          StatusEnum.PartlyPaid,
          StatusEnum.Unpaid,
        ],
      },
    ],
    [currentFarm?.name, optionsStatus, t],
  );

  const handleOnClickCreateInvoicePayment = (id: BaseKey) => {
    if (id) {
      track('Create Invoice Payment');
      paymentDrawer.show(id);
    }
  };

  const handleOnClickCreateInvoice = () => {
    track('Create Invoice');
    createDrawer.show();
  };

  const handleOnClickEditInvoice = (id: BaseKey) => {
    if (id) {
      track('Edit Invoice');
      editDrawer.show(id);
    }
  };

  const { tableProps, searchFormProps, tableQueryResult } =
    useRefineTableFilters<InvoiceModel, HttpError, FilterFormModel>({
      filters: {
        permanent: [
          {
            field: 'isHaveOrder',
            operator: 'eq',
            value: false,
          },
        ],
      },
      listFilters,
      resource: 'purchase-invoice',
    });

  useEffect(() => {
    if (currentFarm && searchFormProps) {
      searchFormProps.form?.setFieldsValue({
        farms: [currentFarm.name],
      });
      searchFormProps.form?.submit();
    }
  }, [currentFarm, searchFormProps.form?.setFieldValue]);

  return {
    tableProps,
    searchFormProps,
    listFilters,
    createDrawer,
    editDrawer,
    paymentDrawer,
    handleOnClickCreateInvoicePayment,
    handleOnClickCreateInvoice,
    handleOnClickEditInvoice,
    tableQueryResult,
  };
};

export { useInvoiceList as default, useInvoiceList };
