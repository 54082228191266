import { type CWElementsConfig, useConfig } from '@cw-elements/config';
import { loadRemoteModule } from '@cw-elements/mfe-setup/load';
import { PLATFORM_DATA_CONFIG } from 'common/constants';
import { lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';

import * as Styled from './ag-operation.style';

enum UnitSystemType {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}

interface AgOperationProps {
  getConfigInstance: () => CWElementsConfig;
  userLanguage: string;
  orgId: string;
  source: string;
  unitSystem?: UnitSystemType;
}

const AgOperation = lazy(() =>
  loadRemoteModule<AgOperationProps>('ag-operation/list'),
);

const AgOperationContainer = () => {
  const { i18n } = useTranslation();
  const userLanguage = i18n.language;
  const { currentOrganizationId } = useOrganization();

  return (
    <Styled.Container data-testid="ag-operation-container">
      <AgOperation
        getConfigInstance={useConfig}
        orgId={currentOrganizationId ?? ''}
        userLanguage={userLanguage}
        source={PLATFORM_DATA_CONFIG.APP_ID}
        unitSystem={UnitSystemType.METRIC}
      />
    </Styled.Container>
  );
};

export { AgOperationContainer };
