import { GreenColors, RedColors, TealColors, YellowColors } from 'theme';

export const CustomReportsTestId = {
  CUSTOM_REPORTS_DRAWER: 'custom-reports-drawer',
  CUSTOM_REPORTS_TITLE: 'custom-reports-title',
  CUSTOM_REPORTS_CARD_BUTTON: 'custom-reports-card-button',
  CUSTOM_REPORTS_LOADING_CARD: 'custom-reports-loading-card',
  CUSTOM_REPORTS_EMPTY_STATE: 'custom-reports-empty-state',
};

export const CustomReportsColor = [
  GreenColors,
  RedColors,
  YellowColors,
  TealColors,
];

export const CustomReportsWhatsappLink =
  'https://wa.me/5531995130088?text=Ol%C3%A1%2C%20gostaria%20de%20um%20novo%20relat%C3%B3rio%20no%20Cropwise%20Balance';
