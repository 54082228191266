import { useSelectBalance } from 'common/hooks/use-select-balance.hook';
import { useMemo } from 'react';
import { useOrganization } from 'src/organization';

const useItemCategoryFormDetails = () => {
  const { currentOrganizationId, isLoading: isOrganizationLoading } =
    useOrganization();

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const loadItemCategoryList = useMemo(
    () => Boolean(!!currentOrganizationId && !isOrganizationLoading),
    [currentOrganizationId, isOrganizationLoading],
  );

  const {
    selectProps: itemCategorySelectProps,
    query: itemCategoryQueryResult,
  } = useSelectBalance({
    resource: 'item-category',
    optionValue: 'id',
    optionLabel: 'id',
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    meta: metadata,
    queryOptions: {
      enabled: loadItemCategoryList,
    },
  });

  return {
    itemCategorySelectProps,
    isLoading: itemCategoryQueryResult.isLoading,
  };
};

export { useItemCategoryFormDetails as default, useItemCategoryFormDetails };
