import { UseDrawerFormReturnType } from '@refinedev/antd';
import { HttpError, useDelete, useUpdate } from '@refinedev/core';
import { useSelectBalance } from 'common/hooks/use-select-balance.hook';
import { FarmModel } from 'common/models';
import { useTracking } from 'common/utils';
import { useMemo } from 'react';
import { useOrganization } from 'src/organization';

import { BankAccountRequestModel } from '../bank-account.model';

interface UseBankAccountFormProps {
  bankAccountDrawerForm: UseDrawerFormReturnType<
    BankAccountRequestModel,
    HttpError,
    BankAccountRequestModel
  >;
  refetch?: () => void;
}

const useBankAccountForm = ({
  bankAccountDrawerForm,
  refetch,
}: UseBankAccountFormProps) => {
  const { track } = useTracking();

  const { currentOrganization } = useOrganization();
  const { mutate: mutateDelete } = useDelete();
  const { mutate: mutateUpdate } = useUpdate();

  const {
    drawerProps,
    formProps,
    close: onClose,
    saveButtonProps: onCreate,
    formLoading: isLoading,
  } = bankAccountDrawerForm;

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganization?.id,
    }),
    [currentOrganization?.id],
  );

  const { selectProps: farmSelectProps } = useSelectBalance<FarmModel>({
    resource: 'farm',
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    pagination: {
      pageSize: 50,
    },
    meta: metadata,
  });

  const handleOnFinish = (values: BankAccountRequestModel) => {
    if (formProps.onFinish && !values.id) {
      formProps.onFinish({
        ...values,
      });
    } else {
      mutateUpdate(
        {
          resource: 'account',
          id: 'custom',
          values: {
            ...values,
          },
        },
        {
          onSuccess: () => {
            onClose();
            refetch?.();
            track('Bank Account - Update Finish');
          },
        },
      );
    }
  };

  const handleOnClickDelete = () => {
    mutateDelete(
      {
        resource: 'account/custom',
        id: formProps?.initialValues?.id,
      },
      {
        onSuccess: () => {
          onClose();
          refetch?.();
          track('Delete BankAccount');
        },
      },
    );
  };

  return {
    drawerProps,
    onCreate,
    isLoading,
    handleOnClickDelete,
    onClose,
    formProps,
    handleOnFinish,
    farmSelectProps,
  };
};

export { useBankAccountForm as default, useBankAccountForm };
