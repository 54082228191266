import { FormProps, Grid, SelectProps } from 'antd';
import { MouseEvent, ReactNode } from 'react';

import { type OptionTypeModel, SelectWithComplementaryLabel } from '../../';
import * as Styled from './select-with-clear.style';

interface SelectWithClearProps extends SelectProps {
  name: string;
  formProps?: FormProps;
  isSendFormAfterClear?: boolean;
  options: OptionTypeModel[];
  onClickClearIfEmpty?: () => void;
}

declare type CustomTagProps = {
  label: ReactNode;
  value: string;
  disabled: boolean;
  onClose: (event?: MouseEvent<HTMLElement>) => void;
  closable: boolean;
};

export const SelectWithClear = ({
  formProps,
  name,
  isSendFormAfterClear,
  onClickClearIfEmpty,
  options,
  ...props
}: SelectWithClearProps) => {
  const isMobile = Grid.useBreakpoint()?.xs;
  const handleClearSelect = () => {
    if (!formProps?.form) {
      return;
    }
    const valueForm = formProps.form?.getFieldValue(name);
    formProps.form?.setFieldValue(name, undefined);
    if (isSendFormAfterClear) {
      formProps.form?.submit();
    }
    if (onClickClearIfEmpty && !valueForm) {
      onClickClearIfEmpty();
    }
  };

  const renderTag = ({ label, onClose }: CustomTagProps) => {
    return (
      <Styled.Tag closable onClose={onClose}>
        {label}
      </Styled.Tag>
    );
  };

  return (
    <Styled.Container>
      <Styled.BoxSelect>
        <SelectWithComplementaryLabel
          tagRender={renderTag}
          variant="borderless"
          dropdownStyle={{ minWidth: isMobile ? 220 : 400 }}
          style={{ minWidth: 60, paddingRight: 8, ...props.style }}
          {...props}
          options={options}
        />
      </Styled.BoxSelect>
      <Styled.BoxButton>
        <Styled.Button onClick={handleClearSelect} type="text">
          <Styled.ContainerIcon>
            <Styled.IconClear />
          </Styled.ContainerIcon>
        </Styled.Button>
      </Styled.BoxButton>
    </Styled.Container>
  );
};
