import { Form, FormProps, Skeleton } from 'antd';
import { SelectWithButton } from 'common/components';
import { useSelectBalance } from 'common/hooks/use-select-balance.hook';
import {
  CropProtectionIcon,
  FertilizerIcon,
  GenericIcon,
  VarietyIcon,
} from 'common/icons';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';
import { CatalogModel } from 'src/resources/item/item.model';
import { ItemTypeEnum } from 'src/resources/item/item-type.enum';

import * as Styled from './search-item.style';

interface SearchItemProps {
  formProps: FormProps;
  setDisabledFields: Dispatch<SetStateAction<string[]>>;
  handleGoToNextStep: () => void;
}

const SearchItem = ({
  formProps,
  setDisabledFields,
  handleGoToNextStep,
}: SearchItemProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [isStartFetching, setIsStartFetching] = useState<boolean>(true);
  const selectedType: ItemTypeEnum = formProps?.form?.getFieldValue('itemType');
  const formValues = Form.useWatch([], formProps.form);
  const { t } = useTranslation();
  const { currentOrganization } = useOrganization();
  const metadata = useMemo(
    () => ({
      organizationId: currentOrganization?.id,
    }),
    [currentOrganization?.id],
  );

  const { selectProps, query } = useSelectBalance<CatalogModel>({
    optionLabel: 'name',
    resource: `item/catalog/${selectedType ?? ''}`,
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    meta: metadata,
    queryOptions: {
      enabled: !!selectedType,
    },
    errorNotification: false,
  });

  useEffect(() => {
    const idSelectItem = formProps.form?.getFieldValue('selectItem');
    if (idSelectItem) {
      const catalog = query.data?.data.find((data) => data.id === idSelectItem);
      if (catalog) {
        formProps.form?.setFields([
          {
            name: 'name',
            value: catalog.name,
          },
          {
            name: 'uom',
            value: catalog.stockUom,
          },
          {
            name: 'category',
            value: catalog.category,
          },
        ]);
        const disabledFields = ['name', 'category'];
        if (catalog.stockUom) {
          disabledFields.push('uom');
        }
        setDisabledFields(disabledFields);
      }
    } else {
      setDisabledFields([]);
    }
  }, [formValues]);

  useEffect(() => {
    formProps.form?.setFields([
      {
        name: 'name',
        value: '',
      },
      {
        name: 'uom',
        value: '',
      },
      {
        name: 'category',
        value: '',
      },
      {
        name: 'selectItem',
        value: '',
      },
    ]);
  }, []);

  useEffect(() => {
    if (!query.isFetching) {
      setIsStartFetching(false);
    }
  }, [query.isFetching]);

  const getIcon = useMemo(() => {
    switch (selectedType) {
      case ItemTypeEnum.FERTILIZER:
        return <FertilizerIcon />;
      case ItemTypeEnum.GENERIC:
        return <GenericIcon />;
      case ItemTypeEnum.CROP_PROTECTION:
        return <CropProtectionIcon />;
      case ItemTypeEnum.SEED:
      default:
        return <VarietyIcon />;
    }
  }, [selectedType]);

  const handleOnSearch = (value: string) => {
    setSearchValue(value);
    if (selectProps?.onSearch) {
      selectProps.onSearch(value);
    }
    return value;
  };

  const handleOnClick = () => {
    formProps.form?.setFieldValue('name', searchValue ?? '');
    handleGoToNextStep();
  };

  return (
    <Styled.Card size="small">
      <Styled.Tag>
        {getIcon}
        {t(`item.types.${selectedType}`)}
      </Styled.Tag>
      {!isStartFetching ? (
        <Form.Item label={t('item.search_item')} name={['selectItem']}>
          <SelectWithButton
            {...selectProps}
            allowClear
            onSearch={handleOnSearch}
            buttonProps={{
              children: t('item.its_new'),
              onClick: handleOnClick,
            }}
          />
        </Form.Item>
      ) : (
        <Skeleton active />
      )}
    </Styled.Card>
  );
};

export { SearchItem };
