import { Button, Select as SelectAntd } from 'antd';
import { SelectableButton } from 'common/components';
import styled from 'styled-components';
import { BlueColors } from 'theme';

const Title = styled.h3`
  margin: 16px 0px;
`;

const ContainerFlex = styled.div`
  display: flex;
`;

const ContainerFlexBetween = styled(ContainerFlex)`
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 16px;
`;

const ButtonDownload = styled(Button)`
  margin-left: 16px;
  background-color: ${BlueColors[50]};
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    margin-left: 8px;
  }
`;

const Select = styled(SelectAntd)`
  width: 260px;
`;

const ButtonSelectWidth = styled(SelectableButton)`
  width: 100%;
  justify-content: flex-start;
`;

export {
  ButtonDownload,
  ButtonSelectWidth,
  ContainerFlex,
  ContainerFlexBetween,
  Select,
  Title,
};
