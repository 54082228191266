import { Card as CardAntd } from 'antd';
import { ColorsType } from 'src/theme/colors';
import styled from 'styled-components';
import { NeutralColors } from 'theme';

interface CardIconProps {
  iconColor: ColorsType;
}

const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Card = styled(CardAntd)`
  width: 270px;
  padding: 4px;
  min-height: 100px;
  cursor: pointer;
`;

const CardIcon = styled.div<CardIconProps>`
  background-color: ${({ iconColor }) => iconColor[10]};
  color: ${({ iconColor }) => iconColor[60]};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  svg {
    fill: ${NeutralColors[80]};
    max-width: 20px;
  }
`;

const Description = styled.p`
  min-height: 22px;
`;

export { Card, CardIcon, ContainerButton, Description };
