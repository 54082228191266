import { Dropdown, MenuProps, Space, Table } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import { PageTitle } from 'common/components';
import { usePermissionsBalance } from 'common/hooks/use-permissions-balance.hook';
import { AddIcon, EyeOutlinedIcon } from 'common/icons';
import { RefineTableFilters } from 'common/refine-table-filters';
import { ActionRbacEnum } from 'common/utils/auth-provider.util';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { AgOperationsTypeEnum } from 'src/ag-operation-create/ag-operation.enum';
import EntitlementEnum from 'src/organization/entitlements.enum';

import { CropZoneColumn } from './crop-zone-column/crop-zone-column.component';
import { DateColumn } from './date-column/date-column.component';
import { MaterialConsumptionModel } from './material-consumption.model';
import { MaterialConsumptionForm } from './material-consumption-form/material-consumption-form.component';
import { useMaterialConsumptionList } from './material-consumption-list.hook';
import * as Styled from './material-consumption-list.style';
import { StatusColumn } from './status-column/status-column.component';
import { TypeColumn } from './type-column/type-column.component';

const MaterialConsumptionListContainer = () => {
  const { t } = useTranslation();
  const {
    agOperationFms1280Create,
    agOperationFms1384LandPreparation,
    agOperationFms1763HarvestCreate,
  } = useFlags();
  const { permissions } = usePermissionsBalance();
  const {
    materialConsumptionFms652EnableDeletion,
    materialConsumptionFms579EnableEdition,
  } = useFlags();
  const {
    entitlements,
    tableReturn,
    createDrawer,
    editDrawer,
    listFilters,
    handleOnClickCreateMaterialConsumption,
    handleOnClickEditMaterialConsumption,
    handleOnClickCreateAgOperation,
  } = useMaterialConsumptionList();

  const items: MenuProps['items'] = [
    ...(agOperationFms1280Create
      ? [
          {
            label: t('material_consumption.create_buttons.application'),
            key: '0',
            onClick: () =>
              handleOnClickCreateAgOperation(AgOperationsTypeEnum.SPRAYING),
            icon: <Styled.SprayIconContainer />,
          },
        ]
      : []),
    ...(agOperationFms1384LandPreparation
      ? [
          {
            label: t('material_consumption.create_buttons.land_preparation'),
            key: '1',
            onClick: () =>
              handleOnClickCreateAgOperation(
                AgOperationsTypeEnum.LAND_PREPARATION,
              ),
            icon: <Styled.LandPreparationIconContainer />,
          },
        ]
      : []),
    ...(agOperationFms1280Create
      ? [
          {
            label: t('material_consumption.create_buttons.planting'),
            key: '2',
            onClick: () =>
              handleOnClickCreateAgOperation(AgOperationsTypeEnum.PLANTING),
            icon: <Styled.PlantingIconIconContainer />,
          },
        ]
      : []),
    ...(agOperationFms1763HarvestCreate
      ? [
          {
            label: t('material_consumption.create_buttons.harvest'),
            key: '3',
            onClick: () =>
              handleOnClickCreateAgOperation(AgOperationsTypeEnum.HARVESTING),
            icon: <Styled.HarvestIconContainer />,
          },
        ]
      : []),
    ...(agOperationFms1280Create &&
    entitlements.includes(EntitlementEnum.GENERIC_MATERIAL_CONSUMPTION)
      ? [
          {
            type: 'divider',
          } as ItemType,
        ]
      : []),
    ...(entitlements.includes(EntitlementEnum.GENERIC_MATERIAL_CONSUMPTION)
      ? [
          {
            label: t('material_consumption.create_buttons.generic'),
            key: '4',
            onClick: handleOnClickCreateMaterialConsumption,
            icon: <Styled.OutboxIconContainer />,
          },
        ]
      : []),
  ];

  const onRow = (data: MaterialConsumptionModel) => ({
    onClick: (event: MouseEvent<HTMLElement>) => {
      const target = event.target as HTMLElement;
      if (target.tagName !== 'TD') {
        event.stopPropagation();
        return;
      }

      if (materialConsumptionFms579EnableEdition) {
        handleOnClickEditMaterialConsumption(data.id);
      }
    },
  });

  return (
    <>
      <MaterialConsumptionForm
        drawerProps={createDrawer.drawerProps}
        formProps={createDrawer.formProps}
        onClose={createDrawer.close}
        onCreate={createDrawer.saveButtonProps}
        formInstance={createDrawer.form}
      />
      <MaterialConsumptionForm
        drawerProps={editDrawer.drawerProps}
        formProps={editDrawer.formProps}
        onClose={editDrawer.close}
        onCreate={editDrawer.saveButtonProps}
        queryResult={editDrawer.queryResult}
        formInstance={editDrawer.form}
      />
      <PageTitle
        title={t('material_consumption.title')}
        buttons={
          permissions?.includes(ActionRbacEnum.STOCK_BALANCE_READ) && (
            <Dropdown menu={{ items }} trigger={['click']}>
              <Styled.CreateButton
                onClick={(e) => e.preventDefault()}
                icon={<AddIcon />}
                type="primary"
              >
                {t('buttons.create')}
              </Styled.CreateButton>
            </Dropdown>
          )
        }
      />
      <RefineTableFilters
        searchFormProps={tableReturn.searchFormProps}
        initialListFilters={listFilters}
      />
      <Table
        {...tableReturn.tableProps}
        rowKey="id"
        onRow={onRow}
        pagination={{
          ...tableReturn?.tableProps?.pagination,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
        }}
      >
        <Table.Column
          dataIndex="posting_date"
          title={t('material_consumption.table.date')}
          render={(_, materialConsumption: MaterialConsumptionModel) => (
            <DateColumn materialConsumption={materialConsumption} />
          )}
          sorter
        />
        <Table.Column
          dataIndex="company"
          title={t('material_consumption.table.farm')}
          render={(_, { farm }: MaterialConsumptionModel) => farm}
          sorter
        />
        <Table.Column
          dataIndex="externalId2"
          title={t('material_consumption.table.type')}
          render={(_, materialConsumption: MaterialConsumptionModel) => (
            <TypeColumn materialConsumption={materialConsumption} />
          )}
        />
        <Table.Column
          dataIndex="cropZone"
          title={t('material_consumption.table.crop_zone')}
          render={(value) => <CropZoneColumn value={value} />}
        />
        <Table.Column
          dataIndex="docstatus"
          title={t('material_consumption.table.status')}
          render={(statusId) => <StatusColumn statusId={statusId} />}
        />
        {materialConsumptionFms652EnableDeletion && (
          <Table.Column
            title={t('material_consumption.table.actions')}
            render={(_, { id }: MaterialConsumptionModel) => (
              <Space>
                <Styled.ViewButton
                  icon={<EyeOutlinedIcon />}
                  onClick={() => handleOnClickEditMaterialConsumption(id)}
                />
              </Space>
            )}
          />
        )}
      </Table>
    </>
  );
};

export default MaterialConsumptionListContainer;
