import { useOne } from '@refinedev/core';
import { Skeleton, Typography } from 'antd';
import { PageTitle } from 'common/components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useOrganization } from 'src/organization';

import { DataImportPreviewModel } from '../../reports.model';
import { DataImportTable } from '../data-import-table/data-import-table.component';

const DataImportDetailsContainer = () => {
  const { idDataImport } = useParams();
  const { t } = useTranslation();
  const { currentOrganizationId } = useOrganization();

  const { data, isFetching } = useOne<DataImportPreviewModel>({
    resource: 'data-import/preview',
    id: idDataImport,
    meta: {
      organizationId: currentOrganizationId,
    },
  });

  const dataImportPreview = useMemo(() => data?.data, [data]);

  return (
    <>
      <PageTitle title={t('reports.data_import_details.title')} hasBackButton />
      <Typography.Title level={5}>
        {t('reports.data_import_details.description')}
      </Typography.Title>
      {isFetching ? (
        <Skeleton active />
      ) : (
        <DataImportTable dataImportPreview={dataImportPreview} />
      )}
    </>
  );
};

export { DataImportDetailsContainer };
