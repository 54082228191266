import { track } from '@amplitude/analytics-browser';
import { useOne } from '@refinedev/core';
import { useForm, useWatch } from 'antd/es/form/Form';
import { useSelectBalance } from 'common/hooks/use-select-balance.hook';
import { downloadFile } from 'common/utils/download.util';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';
import { FileTypeEnum } from 'src/reports/reports.enum';

import { FileTypeExtension } from './../../reports.constant';

interface CustomReportsHook {
  reportName: string | undefined;
  setReportName: (reportName: string | undefined) => void;
}

export const useCustomReportsDrawer = ({
  reportName,
  setReportName,
}: CustomReportsHook) => {
  const { i18n } = useTranslation();
  const [isDownload, setIsDownload] = useState<boolean>(false);
  const [hasSearchValue, setHasSearchValue] = useState(false);
  const [open, setOpen] = useState(false);
  const [fileType, setFileType] = useState<FileTypeEnum>();
  const userLanguage = i18n.language.toLocaleLowerCase();
  const { currentOrganizationId } = useOrganization();
  const [formInstance] = useForm();

  const formValues = useWatch([], formInstance);

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const { selectProps: farmSelectProps, query } = useSelectBalance({
    optionLabel: 'name',
    optionValue: 'id',
    resource: 'farm',
    onSearch: (value) => {
      setHasSearchValue(!!value);

      return [
        {
          field: 'name',
          operator: 'eq',
          value: value,
        },
      ];
    },
    pagination: {
      pageSize: 50,
    },
    meta: metadata,
    queryOptions: {
      enabled: !!metadata.organizationId,
    },
  });

  const showDrawer = () => {
    setOpen(true);
  };

  const hideDrawer = () => {
    setOpen(false);
    setFileType(undefined);
    formInstance.resetFields();
  };

  useEffect(() => {
    if (reportName) {
      showDrawer();
    } else {
      hideDrawer();
    }
  }, [reportName]);

  const {
    data: dataCsv,
    refetch,
    remove,
    isFetching,
  } = useOne({
    resource: 'report/export',
    id: isDownload && fileType ? FileTypeExtension[fileType] : undefined,
    meta: {
      params: {
        reportName,
        language: userLanguage,
        fromDate: formValues?.['date-range']?.[0].format('YYYY-MM-DD'),
        toDate: formValues?.['date-range']?.[1].format('YYYY-MM-DD'),
        farm: formValues?.farm,
        ...metadata,
      },
      responseType: 'arraybuffer',
    },
    queryOptions: {
      enabled: isDownload && !!reportName,
    },
  });

  useEffect(() => {
    if (dataCsv) {
      downloadFile(
        dataCsv.data as Blob,
        `${reportName}.${fileType === FileTypeEnum.CSV ? 'csv' : 'xlsx'}`,
      );
    }

    track('Reports - Download Custom Report', {
      fileType,
    });
    setIsDownload(false);
    setReportName(undefined);
  }, [dataCsv]);

  const handleOnClickDownload = () => {
    if (dataCsv) {
      remove();
      refetch();
    }
    setIsDownload(true);
  };

  useEffect(() => {
    if (!hasSearchValue && query.data?.data?.length === 1) {
      formInstance.setFieldsValue({ farm: query.data.data[0].id });
    }
  }, [formInstance, hasSearchValue, query.data]);

  const disableSave = useMemo(
    () => !fileType || !reportName || !formValues?.['date-range'],
    [fileType, reportName, formValues?.farm, formValues?.['date-range']],
  );

  return {
    open,
    fileType,
    isFetching,
    setFileType,
    handleOnClickDownload,
    farmSelectProps,
    formInstance,
    disableSave,
  };
};
