import { track } from '@amplitude/analytics-browser';
import { CardButton } from 'common/components';
import { BeenhereIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';

import {
  CustomReportsTestId,
  CustomReportsWhatsappLink,
} from '../custom-reports.constant';

const CustomReportsEmptyState = () => {
  const { t } = useTranslation();

  const openWhatsappLink = () => {
    track('Reports - Request Custom Report');
    const newWindow = window.open(
      CustomReportsWhatsappLink,
      '_blank',
      'noopener,noreferrer',
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <CardButton
      data-testid={CustomReportsTestId.CUSTOM_REPORTS_EMPTY_STATE}
      title={t('reports.custom_reports.empty_state')}
      icon={<BeenhereIcon />}
      onClick={() => openWhatsappLink()}
    />
  );
};

export { CustomReportsEmptyState, CustomReportsEmptyState as default };
