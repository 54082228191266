import { BaseKey } from '@refinedev/core';
import { FormProps } from 'antd';
import { useSelectBalance } from 'common/hooks/use-select-balance.hook';
import { useTracking } from 'common/utils';
import { useMemo } from 'react';
import { useOrganization } from 'src/organization';

import { FarmEditModel } from '../../settings.model';

interface UseCostCenterEditProps {
  formProps: FormProps;
  editId?: BaseKey;
}

const useCostCenterEdit = ({ formProps, editId }: UseCostCenterEditProps) => {
  const { currentOrganizationId } = useOrganization();
  const { track } = useTracking();

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const { selectProps: costCenterSelectProps } = useSelectBalance({
    resource: 'cost-center',
    optionLabel: 'costCenterName',
    optionValue: 'id',
    pagination: {
      pageSize: 100,
    },
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value: value,
      },
    ],
    filters: [
      {
        field: 'farm',
        operator: 'eq',
        value: editId,
      },
    ],
    queryOptions: {
      enabled: !!metadata.organizationId,
    },
    meta: metadata,
  });

  const handleOnFinish = (values: FarmEditModel) => {
    track('End Cost Center Edition');

    if (formProps.onFinish) {
      const id = editId;

      const data = {
        id,
        costCenter: values.costCenter,
      };

      return formProps.onFinish(data);
    }
  };

  return {
    costCenterSelectProps,
    handleOnFinish,
  };
};

export { useCostCenterEdit as default, useCostCenterEdit };
