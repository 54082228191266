import { Alert, Button, ButtonProps, Table as TableAntd } from 'antd';
import styled from 'styled-components';
import { BlueColors, MediaQuery, NeutralColors } from 'theme';

interface ButtonIconProps extends ButtonProps {
  isFirst?: boolean;
  isLast?: boolean;
}

interface ArchivedButtonProps {
  isSelected: boolean;
}

const CardIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 40px;
  height: 40px;
  margin: 8px auto;

  svg {
    transform: scale(1.5);
    fill: ${BlueColors[60]} !important;
  }
`;

const FormItem = styled.div`
  display: flex;
  margin: 24px 0;

  button {
    :first {
      border-radius: 0px;
    }
  }
`;

const ButtonIcon = styled(Button)<ButtonIconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: ${(props) =>
    props.isLast ? '12px' : '0'} !important;
  border-top-left-radius: ${(props) =>
    props.isFirst ? '12px' : '0'} !important;
  border-bottom-right-radius: ${(props) =>
    props.isLast ? '12px' : '0'} !important;
  border-bottom-left-radius: ${(props) =>
    props.isFirst ? '12px' : '0'} !important;

  min-width: 50%;

  @media (min-width: ${MediaQuery.screenSizesCss.tablet}) {
    min-width: 140px;
  }

  svg {
    width: 16px;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 24px;
`;

const ContainerBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RefreshButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  height: 40px;
  width: 40px;
  margin-right: 8px;
  padding: 0px;
`;

const ArchivedButton = styled(Button)<ArchivedButtonProps>`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.isSelected ? BlueColors[10] : NeutralColors[10]};
  color: ${(props) => (props.isSelected ? BlueColors[60] : NeutralColors[60])};
  border: none;
  font-weight: 500;
`;

const Table = styled(TableAntd)<any>`
  border: 1px solid ${NeutralColors[10]};
  border-radius: ${(props) => (props.hasHeader ? '0 0 12px 12px' : '12px')};
`;

const TableHeader = styled.div`
  margin-top: 16px;
  border: 1px solid ${NeutralColors[10]};
  border-radius: 12px 12px 0 0;
  border-bottom: 0;
  padding: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const TextHeader = styled.p`
  margin: 0;
`;

const ButtonSize = styled.div`
  background-color: ${NeutralColors[0]};
  border-radius: 16px;
  color: ${NeutralColors[70]};
  padding: 0 4px;
  margin-left: 4px;
  height: 16px;
  font-size: 10px;
  font-weight: 700;
  border: 1px solid ${NeutralColors[30]};
`;

const BlockAlert = styled(Alert)`
  background-color: ${BlueColors[10]};
  border: 1px solid ${BlueColors[30]};
  margin-bottom: 16px;

  svg {
    fill: ${BlueColors[50]};
  }
`;

export {
  ArchivedButton,
  BlockAlert,
  ButtonIcon,
  ButtonSize,
  CardIcon,
  ContainerBetween,
  FilterContainer,
  FormItem,
  RefreshButton,
  Table,
  TableHeader,
  TextHeader,
};
