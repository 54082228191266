import { FormListFieldData, InputNumber } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { DefaultOptionType } from 'antd/es/select';
import { CurrencyValue } from 'common/components';
import { useSelectBalance } from 'common/hooks/use-select-balance.hook';
import { CheckIcon, HelpIcon } from 'common/icons';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ElectronicInvoiceItemRequest,
  ElectronicInvoicesWithOrderRequest,
} from 'src/orders-and-payments/electronic-invoice/electronic-invoice.model';
import {
  replaceEqualItemCode,
  verifyWarningUom,
} from 'src/orders-and-payments/electronic-invoice/electronic-invoice.util';
import { ItemLinkSelectTestId } from 'src/orders-and-payments/electronic-invoice/electronic-invoice-test-id.constants';
import { PurchaseDetailModel } from 'src/orders-and-payments/purchase';
import { useOrganization } from 'src/organization';
import { ItemModel } from 'src/resources/item';

import * as StyledLink from '../item-link.style';
import * as Styled from './item-link-select.style';

interface ItemLinkSelectProps {
  item: ElectronicInvoiceItemRequest;
  itemFieldData: FormListFieldData;
  fatherItemFieldData: FormListFieldData;
  setIsEditing: (b: boolean) => void;
  setStartLink: (b: boolean) => void;
  openCreateItem: () => void;
  purchaseOrder?: PurchaseDetailModel;
}

const ItemLinkSelect = ({
  item,
  itemFieldData,
  fatherItemFieldData,
  purchaseOrder,
  openCreateItem,
  setIsEditing,
  setStartLink,
}: ItemLinkSelectProps) => {
  const [seeAllItems, setSeeAllItems] = useState(false);

  const allData = useWatch<ElectronicInvoicesWithOrderRequest>([]);
  const selectedItem = useWatch([
    'electronicInvoices',
    fatherItemFieldData.name,
    'items',
    itemFieldData.name,
  ]);
  const { t } = useTranslation();
  const { currentOrganizationId } = useOrganization();
  const form = useFormInstance();

  const optionsPurchaseOrder: DefaultOptionType[] = useMemo(
    () =>
      [
        ...(purchaseOrder?.items?.map((item) => ({
          label: item?.itemName ?? '',
          value: item?.id ?? '',
        })) ?? []),
        {
          label: selectedItem?.suggestedItemName,
          value: selectedItem?.suggestedItemCode,
        },
      ].reduce(
        (
          previousValue: DefaultOptionType[],
          currentValue: DefaultOptionType,
        ) => {
          const isDuplicate = previousValue.some(
            (item: DefaultOptionType) =>
              item.value === currentValue.value || !currentValue.value,
          );
          if (!isDuplicate) {
            previousValue.push(currentValue);
          }
          return previousValue;
        },
        [],
      ),
    [purchaseOrder, selectedItem],
  );

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const { selectProps, query } = useSelectBalance<ItemModel>({
    resource: 'item',
    optionLabel: 'name',
    meta: metadata,
    onSearch: (value) => [
      {
        field: 'name',
        operator: 'eq',
        value,
      },
    ],
  });

  useEffect(() => {
    if (item.suggestedItemCode) {
      let name = '';
      let stockUom = '';

      if (purchaseOrder && !seeAllItems) {
        const itemSelected = purchaseOrder.items.find(
          (currentItem) => currentItem.id === item.suggestedItemCode,
        );

        name = itemSelected?.itemName ?? '';
        stockUom = itemSelected?.stockUom ?? '';
      } else {
        const itemSelected = query.data?.data?.find(
          (currentItem) => currentItem.id === item.suggestedItemCode,
        );

        name = itemSelected?.name ?? '';
        stockUom = itemSelected?.stockUom ?? '';
      }

      if (name !== item.suggestedItemName && name) {
        form.setFieldValue(
          [
            'electronicInvoices',
            fatherItemFieldData.name,
            'items',
            itemFieldData.name,
            'suggestedItemName',
          ],
          name,
        );
        form.setFieldValue(
          [
            'electronicInvoices',
            fatherItemFieldData.name,
            'items',
            itemFieldData.name,
            'suggestedItemUom',
          ],
          stockUom,
        );
        form.setFieldValue(
          [
            'electronicInvoices',
            fatherItemFieldData.name,
            'items',
            itemFieldData.name,
            'uomVerified',
          ],
          true,
        );
      }
    }
  }, [item]);

  const handleFinishLink = () => {
    form?.setFieldValue(
      'electronicInvoices',
      allData?.electronicInvoices?.map((electronicInvoice) => ({
        ...electronicInvoice,
        items: electronicInvoice?.items?.map((currentItem) =>
          replaceEqualItemCode(currentItem, item),
        ),
      })),
    );
    form?.setFieldValue(
      [
        'electronicInvoices',
        fatherItemFieldData.name,
        'items',
        itemFieldData.name,
        'uomVerified',
      ],
      true,
    );

    setStartLink(false);
    setIsEditing(false);
  };

  const handleChangeSelectType = () => {
    setSeeAllItems(true);
  };

  return (
    <Styled.LineLink>
      <Styled.FormItem
        width="40%"
        name={[itemFieldData.name, 'suggestedItemCode']}
      >
        {purchaseOrder && !seeAllItems ? (
          <Styled.Select
            options={optionsPurchaseOrder}
            defaultValue={t('common.enter_name')}
            buttonProps={{
              children: t('electronic_invoice.items.all_items'),
              onClick: handleChangeSelectType,
            }}
            defaultVisible
            disableVisibleOnClick={false}
          />
        ) : (
          <Styled.Select
            {...selectProps}
            buttonProps={{
              children: t('common.create_new'),
              onClick: openCreateItem,
            }}
            defaultVisible
            defaultValue={t('common.enter_name')}
          />
        )}
      </Styled.FormItem>
      <StyledLink.Item
        width="20%"
        isSuccess={verifyWarningUom(item.uom, item.suggestedItemUom ?? '')}
      >
        {verifyWarningUom(item.uom, item.suggestedItemUom ?? '') ? (
          <CheckIcon />
        ) : (
          <HelpIcon />
        )}
        <Styled.FormItem name={[itemFieldData.name, 'qtyInvoice']}>
          <InputNumber addonAfter={item.suggestedItemUom ?? item.uom} />
        </Styled.FormItem>
      </StyledLink.Item>
      <StyledLink.DetailsContainer>
        <CurrencyValue value={item.totalValue / item.qtyInvoice} />
        {` / ${item.uom}`}
      </StyledLink.DetailsContainer>
      <StyledLink.DetailsContainer>
        <CurrencyValue value={item.totalValue} />
      </StyledLink.DetailsContainer>
      <StyledLink.ButtonLink
        onClick={handleFinishLink}
        data-testid={ItemLinkSelectTestId.ITEM_LINK_SELECT_BUTTON}
      >
        <CheckIcon />
      </StyledLink.ButtonLink>
    </Styled.LineLink>
  );
};

export { ItemLinkSelect };
