import { CardProps } from 'antd';
import { ReactNode } from 'react';
import { ColorsType, NeutralColors } from 'src/theme/colors';

import * as Styled from './card-button.style';

interface CardButtonProps extends CardProps {
  icon: ReactNode;
  status?: ReactNode;
  title?: ReactNode;
  iconColor?: ColorsType;
}

export const CardButton = ({
  icon,
  status,
  title,
  iconColor = NeutralColors,
  ...props
}: CardButtonProps) => {
  return (
    <Styled.Card size="small" {...props} title={undefined}>
      <Styled.ContainerButton>
        <Styled.CardIcon iconColor={iconColor}>{icon}</Styled.CardIcon>
        {status}
      </Styled.ContainerButton>
      {title}
    </Styled.Card>
  );
};
