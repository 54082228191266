import { Skeleton } from 'antd';
import { CardButton } from 'common/components';
import { BeenhereIcon } from 'common/icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import GroupTitleComponent from '../group-title/group-title.component';
import {
  CustomReportsColor,
  CustomReportsTestId,
} from './custom-reports.constant';
import { useCustomReports } from './custom-reports.hook';
import * as Styled from './custom-reports.style';
import { CustomReportsDrawer } from './drawer/custom-reports-drawer.component';
import CustomReportsEmptyState from './empty-state/custom-reports-empty-state.component';

const CustomReports: FC = () => {
  const { t } = useTranslation();

  const {
    customReports,
    selectedReport,
    setSelectedReport,
    handleOnClickReport,
    isLoading,
  } = useCustomReports();

  return (
    <>
      <CustomReportsDrawer
        reportName={selectedReport}
        setReportName={setSelectedReport}
      />
      <GroupTitleComponent
        title={t('reports.custom_reports.title')}
        description={t('reports.custom_reports.description')}
        icon={<BeenhereIcon />}
        data-testid={CustomReportsTestId.CUSTOM_REPORTS_TITLE}
      />
      <Styled.ContainerFlex>
        {isLoading && (
          <Styled.LoadingCard
            data-testid={CustomReportsTestId.CUSTOM_REPORTS_LOADING_CARD}
          >
            <Skeleton paragraph={{ rows: 1 }} active />
          </Styled.LoadingCard>
        )}
        {!isLoading && (
          <>
            {customReports?.map((report, index) => (
              <CardButton
                data-testid={CustomReportsTestId.CUSTOM_REPORTS_CARD_BUTTON}
                key={report.reportName}
                title={report.reportName}
                icon={<BeenhereIcon />}
                iconColor={
                  CustomReportsColor[index % CustomReportsColor.length]
                }
                onClick={() => handleOnClickReport(report.reportName)}
              />
            ))}
            <CustomReportsEmptyState />
          </>
        )}
      </Styled.ContainerFlex>
    </>
  );
};

export { CustomReports, CustomReports as default };
