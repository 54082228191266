import { ROUTES } from 'common/constants';

import {
  FeatureItemKeys,
  FinancialItemKeys,
  OrdersAndPaymentsItemKeys,
  ResourcesItemKeys,
} from './menu-item/feature-list.data';

const PathnameToFeatureKey = {
  [ROUTES.PURCHASE]: OrdersAndPaymentsItemKeys.PURCHASE,
  [ROUTES.INVOICE]: OrdersAndPaymentsItemKeys.INVOICE,
  [ROUTES.ACCOUNTS_PAYABLE]: OrdersAndPaymentsItemKeys.ACCOUNTS_PAYABLE,
  [ROUTES.STOCK]: FeatureItemKeys.STOCK,
  [ROUTES.REPORT_GENERAL_LEDGER]: FeatureItemKeys.REPORT_GENERAL_LEDGER,
  [ROUTES.MATERIAL_CONSUMPTION]: FeatureItemKeys.MATERIAL_CONSUMPTION,
  [ROUTES.FINANCIAL]: FinancialItemKeys.OVERVIEW,
  [ROUTES.FINANCIAL_PRODUCTION]: FinancialItemKeys.PRODUCTION,
  [ROUTES.HELP_CENTER]: FeatureItemKeys.HELP_CENTER,
  [ROUTES.ITEM]: ResourcesItemKeys.ITEM,
  [ROUTES.ITEM_CATEGORY]: ResourcesItemKeys.ITEM_CATEGORY,
  [ROUTES.SUPPLIER]: ResourcesItemKeys.SUPPLIER,
  [ROUTES.DASHBOARD]: FeatureItemKeys.DASHBOARD,
  [ROUTES.ELECTRONIC_INVOICE]: OrdersAndPaymentsItemKeys.ELECTRONIC_INVOICE,
  [ROUTES.AG_OPERATION]: FeatureItemKeys.AG_OPERATION,
  [ROUTES.BANK_ACCOUNT]: ResourcesItemKeys.BANK_ACCOUNT,
  [ROUTES.REPORTS]: FeatureItemKeys.REPORTS,
};

const getFeatureKeyFromPathname = (
  pathname: string,
):
  | FeatureItemKeys
  | ResourcesItemKeys
  | FinancialItemKeys
  | OrdersAndPaymentsItemKeys
  | undefined => {
  return PathnameToFeatureKey[pathname as keyof typeof PathnameToFeatureKey];
};

export { getFeatureKeyFromPathname };
