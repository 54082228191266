import { FormListFieldData } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { useSelectBalance } from 'common/hooks/use-select-balance.hook';
import { useMemo } from 'react';
import { useOrganization } from 'src/organization';

import { StockItemModel } from '../../../stock-summary/stock-summary.model';

const useStockTransferItems = (item: FormListFieldData) => {
  const { currentOrganizationId, isLoading: isOrganizationLoading } =
    useOrganization();

  const sourceWarehouse = useWatch('sourceWarehouse');
  const itemCode = useWatch(['items', item.name, 'itemCode']);
  const itemQty = useWatch(['items', item.name, 'qty']);
  const sourceFarm = useWatch('sourceFarm');

  const metadata = useMemo(
    () => ({
      organizationId: currentOrganizationId,
    }),
    [currentOrganizationId],
  );

  const loadItemsList = useMemo(
    () =>
      !!currentOrganizationId && !isOrganizationLoading && !!sourceWarehouse,
    [currentOrganizationId, isOrganizationLoading, sourceWarehouse],
  );

  const { selectProps: stockItemSelectProps, query: StockItemQueryResult } =
    useSelectBalance<StockItemModel>({
      resource: 'stock',
      optionValue: 'itemCode',
      optionLabel: 'itemName',
      filters: [
        {
          field: 'warehouse',
          operator: 'eq',
          value: sourceWarehouse,
        },
        {
          field: 'farm',
          operator: 'eq',
          value: sourceFarm,
        },
      ],
      onSearch: (value) => [
        {
          field: 'itemName',
          operator: 'eq',
          value: value,
        },
      ],
      meta: metadata,
      queryOptions: {
        enabled: loadItemsList,
      },
    });

  const stockItems = useMemo(() => {
    return StockItemQueryResult.data?.data ?? [];
  }, [StockItemQueryResult.data?.data]);

  return {
    stockItemSelectProps,
    stockItems,
    isLoading: StockItemQueryResult.isLoading,
    itemCode,
    itemQty,
  };
};

export { useStockTransferItems as default, useStockTransferItems };
