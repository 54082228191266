import { DocTypeEnum, FileTypeEnum, GroupDocTypeEnum } from './reports.enum';

export enum ReportsTestId {
  REPORTS_EXPORT_DRAWER = 'report-export-drawer',
  REPORTS_EXPORT_CARD = 'reports-export-card',
  REPORTS_CARD_START = 'data-import-card-start',
  REPORTS_EXPORT_DRAWER_DATA = 'reports-drawer-data',
  REPORTS_BUTTON_RESOURCE = 'reports-button-resource',
}

export enum DataImportButtonResourceTestId {
  REPORTS_BUTTON_RESOURCE_CONTAINER = 'reports-button-resource-container',
}

export const ExportFields = {
  [DocTypeEnum.ITEM]: `{"Item":["item_code","item_group","stock_uom","item_name","is_stock_item","allow_negative_stock","description"]}`,
  [DocTypeEnum.ITEM_GROUP]: `{"Item Group":["item_group_name","parent_item_group","is_group"]}`,
  [DocTypeEnum.PURCHASE_ORDER]: `{"Purchase Order":["title","external_id","transaction_date","company","supplier","currency","status"],"items":["item_code","item_name","schedule_date","qty","rate","warehouse"]}`,
  [DocTypeEnum.SUPPLIER]: `{"Supplier":["supplier_name","supplier_group","supplier_type"]}`,
  [DocTypeEnum.PURCHASE_INVOICE]: `{"Purchase Invoice":["name","posting_date","company","supplier","cash_bank_account","due_date","status","season_id","project","paid_amount","base_grand_total"],"items":["item_code","item_name","qty","rate","warehouse","purchase_order"]}`,
};

export const GroupDocType = {
  [GroupDocTypeEnum.MANAGEMENT]: [
    DocTypeEnum.PURCHASE_ORDER,
    DocTypeEnum.PURCHASE_INVOICE,
  ],
  [GroupDocTypeEnum.RESOURCES]: [
    DocTypeEnum.ITEM,
    DocTypeEnum.ITEM_GROUP,
    DocTypeEnum.SUPPLIER,
  ],
};

export const FileTypeExtension = {
  [FileTypeEnum.CSV]: 'csv',
  [FileTypeEnum.XLSX]: 'xlsx',
};
