import { useDrawerForm, useTable } from '@refinedev/antd';
import { FarmModel } from 'common/models';
import { useTracking } from 'common/utils';
import { useMemo } from 'react';
import { useOrganization } from 'src/organization';

import { CostCenterFarmModel } from './settings.model';

const useSettings = () => {
  const { currentOrganizationId, isLoading: isOrganizationLoading } =
    useOrganization();
  const { track } = useTracking();

  const isDependenciesLoaded = useMemo(
    () => !!currentOrganizationId && !isOrganizationLoading,
    [currentOrganizationId, isOrganizationLoading],
  );

  const { tableProps } = useTable<CostCenterFarmModel>({
    resource: 'cost-center/farm',
    queryOptions: {
      enabled: isDependenciesLoaded,
    },
    meta: {
      organizationId: currentOrganizationId,
    },
    pagination: {
      pageSize: 100,
    },
  });

  const {
    drawerProps: costCenterEditDrawerProps,
    formProps: costCenterEditFormProps,
    saveButtonProps: costCenterEditSaveButtonProps,
    close: costCenterEditClose,
    show: costCenterEditShow,
    id: costCenterEditId,
  } = useDrawerForm<FarmModel>({
    action: 'edit',
    resource: 'farm',
    redirect: false,
    syncWithLocation: {
      key: 'edit',
      syncId: true,
    },
  });

  const handleOnClickEditCostCenter = (id: string) => {
    track('Start Cost Center Edition');
    costCenterEditShow(id);
  };

  return {
    tableProps,
    costCenterEditDrawerProps,
    costCenterEditFormProps,
    costCenterEditSaveButtonProps,
    costCenterEditId,
    costCenterEditClose,
    handleOnClickEditCostCenter,
  };
};

export { useSettings as default, useSettings };
