import { Card } from 'antd';
import styled from 'styled-components';

const ContainerFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  margin-bottom: 24px;
`;

const LoadingCard = styled(Card)`
  width: 270px;
  height: 100px;
`;

export { ContainerFlex, LoadingCard };
