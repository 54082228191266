import { Form, Grid, Select } from 'antd';
import { DrawerWithButtons } from 'common/components';
import { CsvIcon, XlsxIcon } from 'common/icons';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ReportsTestId } from 'src/reports/reports.constant';
import { FileTypeEnum } from 'src/reports/reports.enum';

import { CustomReportsTestId } from '../custom-reports.constant';
import { useCustomReportsDrawer } from './custom-reports-drawer.hook';
import * as Styled from './custom-reports-drawer.style';

interface CustomReportsProps {
  reportName: string | undefined;
  setReportName: (reportName: string | undefined) => void;
}

export const CustomReportsDrawer = ({
  reportName,
  setReportName,
}: CustomReportsProps) => {
  const breakpoint = Grid.useBreakpoint();
  const {
    open,
    isFetching,
    fileType,
    setFileType,
    handleOnClickDownload,
    formInstance,
    farmSelectProps,
    disableSave,
  } = useCustomReportsDrawer({ reportName, setReportName });
  const { t } = useTranslation();

  return (
    <DrawerWithButtons
      open={open}
      title={reportName}
      onClose={() => setReportName(undefined)}
      onBack={() => setReportName(undefined)}
      textSave={t('reports.export_button.modal.ok_button') ?? ''}
      width={breakpoint.sm ? '400px' : '100%'}
      buttonSaveProps={{
        onClick: handleOnClickDownload,
      }}
      disabledSave={disableSave}
      data-testid={CustomReportsTestId.CUSTOM_REPORTS_DRAWER}
      isLoading={isFetching}
    >
      <Form
        layout="vertical"
        form={formInstance}
        initialValues={{
          farm: undefined,
          'date-range': [dayjs().subtract(6, 'months'), dayjs()],
        }}
      >
        <Form.Item
          label={t('reports.custom_reports.drawer.date_range')}
          name="date-range"
        >
          <Styled.RangePicker />
        </Form.Item>
        <Form.Item label={t('reports.custom_reports.drawer.farm')} name="farm">
          <Select {...farmSelectProps} />
        </Form.Item>
      </Form>
      <Styled.Title data-testid={ReportsTestId.REPORTS_EXPORT_DRAWER_DATA}>
        {t('reports.export_button.modal.select_title')}
      </Styled.Title>
      <Styled.ContainerFlexBetween>
        <Styled.ButtonSelectWidth
          icon={<CsvIcon />}
          title={t('reports.export_button.modal.csv_title')}
          description={t('reports.export_button.modal.csv_description')}
          onClick={() => setFileType(FileTypeEnum.CSV)}
          isSelected={fileType === FileTypeEnum.CSV}
        />
        <Styled.ButtonSelectWidth
          icon={<XlsxIcon />}
          title={t('reports.export_button.modal.xlsx_title')}
          description={t('reports.export_button.modal.xlsx_description')}
          onClick={() => setFileType(FileTypeEnum.XLSX)}
          isSelected={fileType === FileTypeEnum.XLSX}
        />
      </Styled.ContainerFlexBetween>
    </DrawerWithButtons>
  );
};
