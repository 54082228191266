import styled from 'styled-components';
import { NeutralColors } from 'theme';

const ItemDescription = styled.div`
  display: grid;
  align-items: start;
  grid-template-rows: 1fr 1fr;
  padding: 8px 0;
  border-bottom: 1px solid ${NeutralColors[20]};
  width: 100%;
`;

export { ItemDescription };
