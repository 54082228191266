import { Button, Space } from 'antd';
import { ReactNode, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'src/organization';

interface UseSurveyNotificationProps {
  researchLink?: string;
  additionalValidation?: boolean;
  title: ReactNode;
  description: ReactNode;
  timeToDisplay?: number; // in milliseconds
}

const useSurveyNotification = ({
  researchLink,
  additionalValidation,
  title,
  description,
  timeToDisplay,
}: UseSurveyNotificationProps) => {
  const { t } = useTranslation();
  const { apiNotification } = useOrganization();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const openResearchLink = () => {
    const newWindow = window.open(
      researchLink,
      '_blank',
      'noopener,noreferrer',
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    const notificationKey = 'sessionNotificationDismissed';
    const notificationDismissed = localStorage.getItem(notificationKey);

    if (additionalValidation && !notificationDismissed) {
      timeoutRef.current = setTimeout(() => {
        apiNotification?.open({
          message: title,
          description: description,
          onClose: () => {
            localStorage.setItem(notificationKey, 'true');
          },
          btn: (
            <Space>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  localStorage.setItem(notificationKey, 'true');
                  apiNotification.destroy();
                  openResearchLink();
                }}
              >
                {t('buttons.answer_now')}
              </Button>
            </Space>
          ),
          duration: 0,
        });
      }, timeToDisplay);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [
    apiNotification,
    t,
    title,
    description,
    additionalValidation,
    timeToDisplay,
  ]);
};

export { useSurveyNotification as default, useSurveyNotification };
