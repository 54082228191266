import {
  Button,
  Dropdown,
  Form,
  Input,
  Select,
  Skeleton,
  Typography,
  Upload,
} from 'antd';
import { PageTitle } from 'common/components';
import { AddIcon, ChevronBottomIcon, CloseIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';

import { DataImportTable } from '../data-import-table/data-import-table.component';
import { useDataImportStart } from './data-import-start.hook';
import * as Styled from './data-import-start.style';

const DataImportStartContainer = () => {
  const { t } = useTranslation();
  const {
    amountErrors,
    dataImportPreview,
    docType,
    itemsDropdown,
    isFetching,
    isFetchingStartImport,
    isLoading,
    nameFile,
    clearInput,
    handleOnChangeSelect,
    handleDownloadMenuClick,
    sendFile,
    startImport,
    importOptions,
  } = useDataImportStart();

  return (
    <Form>
      <PageTitle
        title={t('reports.start_import.title')}
        hasBackButton
        buttons={[
          <Button
            key="submit_button"
            type="primary"
            htmlType="submit"
            disabled={!!amountErrors}
            loading={isFetchingStartImport}
            onClick={startImport}
          >
            {t('reports.start_import.start_import_button')}
          </Button>,
        ]}
      />
      <Styled.AlertMessage
        message={t('reports.start_import.alert_title')}
        description={t('reports.start_import.alert_message')}
        type="warning"
        closable
      />
      <Typography.Title level={5}>
        {t('reports.start_import.doc_type')}
      </Typography.Title>
      <Styled.ContainerFlex>
        <Form.Item name="referenceDoctype">
          <Select
            onChange={handleOnChangeSelect}
            value={docType}
            style={{ width: 220 }}
            options={importOptions.map((value) => ({
              label: t(`reports.doc_type.${value}`),
              value,
            }))}
          />
        </Form.Item>
        <Dropdown
          disabled={!docType}
          menu={{ items: itemsDropdown, onClick: handleDownloadMenuClick }}
        >
          <Styled.DownloadButton loading={isFetching} type="primary">
            {t('reports.start_import.download_template')} <ChevronBottomIcon />
          </Styled.DownloadButton>
        </Dropdown>
      </Styled.ContainerFlex>
      {!!docType && !dataImportPreview && !isLoading && (
        <>
          <Typography.Title level={5}>
            {t('reports.start_import.title_upload')}
          </Typography.Title>
          <Upload.Dragger
            multiple={false}
            beforeUpload={() => false}
            showUploadList={false}
            onChange={sendFile}
          >
            <Styled.CardIcon>
              <AddIcon />
            </Styled.CardIcon>
            <p>{t('reports.start_import.description_upload')}</p>
          </Upload.Dragger>
        </>
      )}
      {dataImportPreview && nameFile && (
        <>
          <Typography.Title level={5}>
            {t('reports.start_import.title_upload')}
          </Typography.Title>
          <Styled.ContainerInput>
            <Input value={nameFile} disabled />
            <Styled.ButtonClear size="small" onClick={clearInput}>
              {t('buttons.clear')} <CloseIcon />
            </Styled.ButtonClear>
          </Styled.ContainerInput>
        </>
      )}
      {isLoading && <Skeleton active />}
      {dataImportPreview && (
        <>
          <Typography.Title level={5}>
            {t('reports.start_import.import_preview')}
          </Typography.Title>
          {!!amountErrors && (
            <Styled.ContainerFlex>
              <Styled.BoxErrors>{amountErrors}</Styled.BoxErrors>
              {t('reports.start_import.errors')}
            </Styled.ContainerFlex>
          )}

          <DataImportTable dataImportPreview={dataImportPreview} />
        </>
      )}
    </Form>
  );
};

export { DataImportStartContainer };
